import React from 'react'

// Vendor
import { matchPath } from 'react-router'

// Rncui
import { LocationRouterContext, MatchRouterContext, ImmutableRouterContext } from './RncRouterContext'

export function useHistory() {
  return React.useContext(ImmutableRouterContext).history
}

export function useLocation() {
  return React.useContext(LocationRouterContext)
}

export function useParams() {
  const match = React.useContext(MatchRouterContext)
  return match ? match.params : {}
}

export function useRouteMatch(path) {
  const location = useLocation()
  return matchPath(location.pathname, path)
}

export function useMatch(path) {
  return React.useContext(MatchRouterContext)
}