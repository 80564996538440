import React from 'react'

// Vendor - Component
import Modal from 'react-modal'
Modal.setAppElement('#app')

import { ButtonIcon } from './Button'


class _Modal extends React.Component {
  static defaultProps = {
    contentLabel: 'modal',
    defaultIsOpen: false
  }

  constructor(props) {
    super(props)
    
    this.state = {
      isOpen: props.defaultIsOpen,
    }
  }

  isControlled = () => this.props.isOpen !== undefined
  handleOpen = () => {
    this.setState({isOpen: true})
    if (this.props.onOpen) this.props.onOpen()
  }
  handleClose = () => {
    this.setState({isOpen: false})
    if (this.props.onClose) this.props.onClose()
  }

  render() {
    const { contentLabel, isOpen: propsIsOpen, size, depth, parentSelector } = this.props
    const { isOpen } = this.state
    const calculatedIsOpen = this.isControlled() ? propsIsOpen : isOpen

    const baseStyle = {
      overlay: {
        zIndex: depth ? depth * 999 : 999,
        background: 'rgba(0,0,0,0.80)',
        position: 'fixed'
      },
      content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
      }
    }

    if (size == 'big') {
      baseStyle.content.left = '20%'
      baseStyle.content.top = '10%'
      // baseStyle.content.marginRight = '-20%'
      // baseStyle.content.transform = 'translate(-20%, -20%)'
      baseStyle.content.marginRight = 'unset'
      baseStyle.content.transform = 'unset'
      baseStyle.content.width = '60%'
      baseStyle.content.maxHeight = '80%'
    } else if (size == 'fullScreen') {
      baseStyle.content.left = '1%'
      baseStyle.content.top = '1%'
      // baseStyle.content.marginRight = '-20%'
      // baseStyle.content.transform = 'translate(-20%, -20%)'
      baseStyle.content.marginRight = 'unset'
      baseStyle.content.transform = 'unset'
      baseStyle.content.width = '96%'
      baseStyle.content.height = '91%'
    }

    return (
      <>
      <Modal
        isOpen={calculatedIsOpen}
        onAfterOpen={this.handleOpen}
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick={false}
        style={baseStyle}
        contentLabel={contentLabel}
        parentSelector={parentSelector}
        >
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ButtonIcon iconName='circle-empty-remove' variant='flat' onClick={this.handleClose}/>
            </div>
          {this.props.children}
      </Modal>
      </>
    )
  }
}

export default _Modal
