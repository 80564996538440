import React from 'react'

// Vendor
import qs from 'qs'
import URI from 'urijs'

// Rncui
import { useLocation, useHistory } from 'runic-aura/router/hooks'

const getInitialFilters = (modelName, query) => {
  const locationFilters = {}
  Object.keys(query).map(filterKey => {
    const splitFilterKey = filterKey.split('__')
    const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

    if (filterBelongsToThisEntityList) {
      locationFilters[splitFilterKey[1]] = query[filterKey]
    }
  })
  return locationFilters
}

const updateUrlWithFilters = (query, locationFilters, modelName, searchSourceOfFiltersRef, history, mode = 'push') => {
  const url = new URI(location.pathname)
  const newFilters = {}

  const currentFilters = query
  Object.keys(currentFilters).map(filterKey => {
    const splitFilterKey = filterKey.split('__')
    const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

    if (!filterBelongsToThisEntityList) {
      newFilters[filterKey] = currentFilters[filterKey]
    }
  })

  const _filters = locationFilters || {}
  Object.keys(_filters).map(filterKey => {
    const filterData = _filters[filterKey]
    if (filterData != null || filterData != undefined) {
      const prefixedFilterKey = `${modelName}__${filterKey}`
      newFilters[prefixedFilterKey] = filterData
    }
  })
  url.setQuery(newFilters)
  searchSourceOfFiltersRef.current = url.search()
  if (mode === 'push') history.push(url.toString())
  else history.replace(url.toString())

  return url.search()
}

const getSortInfo = (newSortinfo, locationFilters) => {
  const {
    sortBy: newSortBy,
    sortDirection: newSortDirecton
  } = newSortinfo

  const {
    sortBy: currentSortBy,
    sortDirection: currentSortDirection
  } = locationFilters

  if (!newSortBy || !newSortDirecton) {
    return {
      sortBy: null,
      sortDirection: null
    }
  } else {
    return {
      sortBy: newSortBy,
      sortDirection: newSortDirecton
    }
  }
}

const useLocationFilter = modelName => {
  const location = useLocation()
  const history = useHistory()
  const query = qs.parse(location.search.slice(1))

  const [locationFilters, _setLocationFilters] = React.useState(getInitialFilters(modelName, query))

  const currentLocationFiltersRef = React.useRef(locationFilters)
  const prevHasFilterRef = React.useRef(false)
  const searchSourceOfFiltersRef = React.useRef(location.search)

  const setLocationFilters = React.useCallback((filters) => {
    const cleanedFilters = {}
    Object.keys(filters).forEach(key => {
      const val = filters[key]
      const isValidValue = val !== null && val !== undefined && val.length !== 0
      if (isValidValue) cleanedFilters[key] = val
    })

    _setLocationFilters(cleanedFilters)
    currentLocationFiltersRef.current = cleanedFilters
    return cleanedFilters
  }, [])

  const updateLocationFilters = React.useCallback((filters, options = {}) => {
    const { mode = 'push', keepDx = false } = options
    let newLocationFilters = {...currentLocationFiltersRef.current, ...filters}
    if (!keepDx) newLocationFilters.dx = undefined
    newLocationFilters = setLocationFilters(newLocationFilters)
    const newSearch = updateUrlWithFilters(query, newLocationFilters, modelName, searchSourceOfFiltersRef, history, mode)
  }, [currentLocationFiltersRef.current, location.search, modelName])

  const handleSort = React.useCallback((newSortinfo) => {
    const sortFilters = getSortInfo(newSortinfo, locationFilters)
    updateLocationFilters(sortFilters)
  }, [locationFilters])

  React.useEffect(() => {
    if (searchSourceOfFiltersRef.current === location.search) return
    const _filters = {}
    const queryFilters = query
    let hasFilter = false
    Object.keys(queryFilters).map(filterKey => {
      const splitFilterKey = filterKey.split('__')
      const filterBelongsToThisEntityList = splitFilterKey.length > 1 && splitFilterKey[0] == modelName

      if (filterBelongsToThisEntityList) {
        hasFilter = true
        _filters[splitFilterKey[1]] = queryFilters[filterKey]
      }
    })

    if (hasFilter) {
      setLocationFilters(_filters)
      prevHasFilterRef.current = true
    } else if (prevHasFilterRef.current && !hasFilter) {
      setLocationFilters(_filters)
      prevHasFilterRef.current = false
    }
    searchSourceOfFiltersRef.current = location.search
  }, [location.search, modelName])

  const resetFilters = () => setLocationFilters({})

  React.useEffect(() => {
    return () => resetFilters()
  }, [modelName])

  const instance = React.useMemo(() => ({
    locationFilters,
    updateLocationFilters,
    resetFilters,
    handleSort,
  }), [locationFilters, modelName])
  
  return instance
}

export default useLocationFilter