import React from 'react'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { StaticRoute, useRouteMatch, useMatch } from 'runic-aura/router'
import { useRncIndexRedirect, useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

const RncStandardDomain = ({
  domain,
  zone,
}) => {
  const match = useMatch()
  const director = useDirector()

  useRncActivate('domain', domain)
  useRncIndexRedirect(match, domain.path, domain.insideIndexPath)

  const unitMatch = useRouteMatch({
    path: `${match.path}:itemKind/:unitName`,
    strict: true,
    sensitive: true
  })

  if (!unitMatch) return null

  const { itemKind, unitName } = unitMatch.params

  const item = domain.items.find(i => i.unitName === unitName)
  if (!item) return null

  const Handler = director.directory.runicAura.unitHandlers[itemKind]

  if (!Handler) return null

  return (
    <StaticRoute match={unitMatch}>
      <Handler domain={domain} item={item} />
    </StaticRoute>
  )
}

export default RncStandardDomain
