// Riva - Function
import { createActions } from 'runic/core/redux'

const BASE_COMPONENT = 'RcSystem'

const ACTIONS = {
  RCR_SOURCE: {
    RCR_GET_ACTION_GROUP: {component: 'RcApp', archive: 'RcrActionGroup'},
    RCR_GET_ACTION_GROUPS_FOR_MODEL: {component: 'RcApp', archive: 'RcrActionGroup'},
  },
}

export default createActions(ACTIONS, 'action', BASE_COMPONENT)
