import React from 'react'

// Reverb
import { styled, t, tt, tc, Spacer } from 'volcano'

import useDropdownTypeahead from '../hooks/useDropdownTypeahead'
import BareTextField from './TextField'

import Popover from './Popover'

const FieldError = styled.div`
  font-size:  ${tt('input.fontSizeError', 'fontSizes.s')};
  color:  ${tt('input.colorError', 'colors.accent')};

  margin-top:  ${t('space.1')}px;
  margin-left: 13px;
  
`

const Error = ({error}) => {
  if (!error) return null

  let message
  if (error.type === 'min') message = `En az ${error.message.min} karakter olmalı.`
  else if (error.type === 'email') message = `Geçerli bir eposta adresi girmelisin.`
  else {
    console.log('Unknown error type', error)
    message = "Geçersiz."
  }

  return (
    <FieldError>
      {message}
    </FieldError>
  )
}

const OptionWrapper = styled.div`
  padding: ${t('space.2')}px;

  ${p => p.selected && `background-color: ${tc('primary9')(p)}`};

  &:hover {
    background-color: ${tc('primary9')};
  }
`

const Option = props => (
  <OptionWrapper {...props}>{props.children}</OptionWrapper>
)


const Dropdown = React.forwardRef(({
  options, inputProps, ...rest
}, ref) => {
  const [_options, selected, popoverProps, _inputProps, getOptionProps, inputRef] = useDropdownTypeahead(options, option => {
    inputProps.onChange(option.value)
  })

  return (
    <Spacer mb={3}>
      <BareTextField {..._inputProps} {...rest} />
      <Popover {...popoverProps}>
        {_options && _options.map((opt, optDx) => (
          <Option key={optDx} {...getOptionProps(opt, selected)}>
            {opt.label}
          </Option>
        ))}
      </Popover>
    </Spacer>
  )
})

export default Dropdown