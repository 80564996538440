// Vendor
import * as changeCase from 'volcano/util/text'

// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import uiActions from './actions'

const defaultState = {
  selectedEntity: {},
  entityListEdit: {},
  activeMenuList: [],
  menu: {},
  appMap: null,
  activeApps: [],
  activeAppKey: null,
  settings: {},
  watch: {}
}

export default createReducer(defaultState, {
  [uiActions.START_WATCH]: (draft, name) => {
    if (draft.watch[name]) {
      draft.watch[name].watchCount += 1
      // draft.watch[name] = {...draft.watch[name], watchCount: draft.watch[name].watchCount + 1}
    } else {
      draft.watch[name] = {watchCount: 1, version: 0}
    }
  },

  [uiActions.STOP_WATCH]: (draft, name) => {
    if (draft.watch[name] && draft.watch[name].watchCount > 1 ) {
      draft.watch[name].watchCount -= 1
    } else {
      draft.watch[name] = {}
    }
  },

  [uiActions.UPDATE_WATCH]: (draft, name) => {
    if (draft.watch[name] && draft.watch[name].watchCount > 0 ) {
      draft.watch[name].version += 1
    }
  },

  [uiActions.RCR_SET_UI_SETTINGS]: (draft, { settings }) => {
    Object.keys(settings).forEach(settingKey => draft.settings[settingKey] = settings[settingKey])
  },

  [uiActions.RCR_TOGGLE_UI_SETTINGS]: (draft, { settings }, state) => {
    settings.forEach(settingKey => {
      if (state.settings[settingKey]) draft.settings[settingKey] = !state.settings[settingKey]
      else draft.settings[settingKey] = true
    })
  },

  [uiActions.RCR_ENTITY_LIST_EDIT_BEGIN]: (draft, { entityKind, items }) => {
    draft.entityListEdit[entityKind] = items
  },

  [uiActions.RCR_ENTITY_LIST_EDIT_REMOVE_ENTITY_AT_INDEX]: (draft, { entityKind, index }, state) => {
    if (!state.entityListEdit[entityKind] || !state.entityListEdit[entityKind].items) return

    draft.entityListEdit[entityKind].items.splice(index, 1)
  },

  [uiActions.RCR_ENTITY_LIST_EDIT_UPDATE_ENTITY_AT_INDEX]: (draft, { entityKind, index, attrName, value }, state) => {
    if (!state.entityListEdit[entityKind] || !state.entityListEdit[entityKind].items) return

    draft.entityListEdit[entityKind].items[index][attrName] = value
  },

  [uiActions.RCR_ENTITY_LIST_EDIT_CHANGE_ENTITY_ORDER]: (draft, { entityKind, sourceIndex, targetIndex }, state) => {
    if (
      !state.entityListEdit[entityKind] ||
      !state.entityListEdit[entityKind].items ||
      !state.entityListEdit[entityKind].items[sourceIndex] ||
      !state.entityListEdit[entityKind].items[targetIndex]
    ) return

    const sourceItem = state.entityListEdit[entityKind].items[sourceIndex]

    draft.entityListEdit[entityKind].items.splice(sourceIndex, 1)
    draft.entityListEdit[entityKind].items.splice(targetIndex, 0, sourceItem)
  },

  [uiActions.RCR_ENTITY_LIST_EDIT_ADD_ENTITY_AT_INDEX]: (draft, { entityKind, index, entity }, state) => {
    if (
      !state.entityListEdit[entityKind] ||
      !state.entityListEdit[entityKind].items
    ) return state

    draft.entityListEdit[entityKind].items.splice(index, 0, entity)
  },

  [uiActions.RCR_CREATE_OR_REPLACE_MENU]: (draft, { kind, menu }, state) => {
    draft.menu[menu.name] = menu

    const activeMenuIndex = state.activeMenuList.findIndex(item => item.kind === kind)

    if (activeMenuIndex === -1) draft.activeMenuList.push({kind, name: menu.name})
    else draft.activeMenuList[activeMenuIndex] = {kind, name: menu.name}
  },

  [uiActions.RCR_REGISTER_APP_MAP]: (draft, { appMap }) => draft.appMap = appMap,

  [uiActions.RCR_ACTIVATE_APP]: (draft, { app, parentPath, params, url }, state) => {
    const path = parentPath ? `${parentPath}.${app.name}` : app.name
    if (state.activePath && state.activePath.indexOf(path) === 0) {
      const activeAppIndex = state.activeApps.findIndex(activeApp => activeApp.appPath == path)
      const activeApp = state.activeApps[activeAppIndex]
      draft.activeApps[activeAppIndex] = {...app, url: url}
      return
    }

    const parentPathNames = parentPath ? parentPath.split('.') : []
    const activeAppList = []

    let lastAppLoaded = state.activeApps[0]
    let lastPath = state.activePath || ''
    let lastPageLoaded = null
    parentPathNames.forEach((parentPathName, index) => {
      const activeAppIndex = state.activeApps.length - index -1

      if (activeAppIndex >= 0 && state.activeApps[activeAppIndex] !== undefined && state.activeApps[activeAppIndex].name == parentPathName) {
        activeAppList.unshift({...state.activeApps[activeAppIndex], params: params})
      } else {
        let item

        const isApp = parentPathName[0] == parentPathName[0].toUpperCase()

        if (isApp) {
          item = state.appMap.apps[parentPathName]
          lastAppLoaded = item
        }
        else {
          if (lastPageLoaded) {
            item = lastPageLoaded.pages.find(page => page.name == parentPathName)
            lastPageLoaded = item
          } else {
            item = lastAppLoaded.pages.find(page => page.name == parentPathName)
            lastPageLoaded = item
          }
        }

        lastPath = lastPath.length > 0 ? `${lastPath}.${item.name}` : item.name
        activeAppList.unshift({...item, appPath: lastPath, params: params})
      }
    })

    activeAppList.unshift({...app, 'appPath': path, url: url, params: params})

    draft.activeApps = activeAppList
    draft.activePath = path
  },

  [uiActions.RCR_DEACTIVATE_APP]: (draft, { item, parentPath }, state) => {
    const deactivatedPath = parentPath || item.name
    const activeAppIndex = state.activeApps.findIndex(activeApp => activeApp.appPath ? activeApp.appPath == deactivatedPath : activeApp.name == deactivatedPath)

    let parentAppIndex = parentAppIndex = state.activeApps.findIndex(activeApp => activeApp.appPath ? activeApp.appPath == parentPath : false)

    if (parentAppIndex == -1) {
      const currentActiveAppIndex = state.activeApps.findIndex(activeApp => activeApp.name == item.name)
      parentAppIndex = currentActiveAppIndex != -1 ? currentActiveAppIndex + 1 : -1
    }

    if (parentAppIndex > 0) {
      draft.activeApps.splice(0, parentAppIndex)
      draft.activePath = parentPath
      return
    } else if (parentAppIndex == 0) {
      draft.activeApps = []
      draft.activePath = null
    } else {
      return
    }
  },

  // [uiActions.RCR_DEACTIVATE_APP]: (state, payload) => {
  //   const { item, parentPath } = payload
  //   const deactivatedPath = parentPath || item.name
  //   const activeAppIndex = state.activeApps.findIndex(activeApp => activeApp.appPath ? activeApp.appPath == deactivatedPath : activeApp.name == deactivatedPath)

  //   if (activeAppIndex > 0) {
  //     const stateUpdate = {
  //       activeApps: {
  //         $splice: [[0, activeAppIndex]]
  //       },
  //       activePath: {
  //         $set: parentPath
  //       }
  //     }
  //     return update(state, stateUpdate)
  //   } else if (activeAppIndex == 0) {
  //     const stateUpdate = {
  //       activeApps: {
  //         $set: []
  //       },
  //       activePath: {
  //         $set: null
  //       }
  //     }
  //     return update(state, stateUpdate)
  //   } else {
  //     return state
  //   }
  // },

  [uiActions.RCR_DEACTIVATE_MENU]: (draft, { kind }, state) => {
    const activeMenuIndex = state.activeMenuList.findIndex(item => item.kind === kind)
    
    if (activeMenuIndex !== -1) draft.activeMenuList.splice(activeMenuIndex, 1)
  },

  [uiActions.CLEAR_SELECTION]: (draft, { entityKind, context }, state) => {
    if (state.selectedEntity[entityKind]) {
      draft.selectedEntity[entityKind][context] = {selectedCount: 0}
    }
  },

  [uiActions.TOGGLE_ENTITY]: (draft, { entityKind, entityListName, entityId }, state) => {
    if (state.selectedEntity[entityKind]) {

      const hasEntityList = !!state.selectedEntity[entityKind][entityListName]
      const isEntityIdSet = state.selectedEntity[entityKind][entityListName][entityId] != undefined

      if (hasEntityList) {

        if (isEntityIdSet) {
          const entityListData = state.selectedEntity[entityKind][entityListName]
          draft.selectedEntity[entityKind][entityListName][entityId] = !entityListData[entityId]

          if (state.selectedEntity[entityKind][entityListName][entityId] == true) {
            draft.selectedEntity[entityKind][entityListName].selectedCount -= 1
            draft.selectedEntity[entityKind][entityListName].selected = ''
          } else {
            draft.selectedEntity[entityKind][entityListName].selectedCount += 1
          }
        } else {
          draft.selectedEntity[entityKind][entityListName][entityId] = true
          draft.selectedEntity[entityKind][entityListName].selectedCount += 1
        }
        
      } else {
        draft.selectedEntity[entityKind][entityListName][entityId] = true
        draft.selectedEntity[entityKind][entityListName].selectedCount = 1
      }
    } else {
      draft.selectedEntity[entityKind] = {
        [entityListName]: {
          [entityId]: true,
          selectedCount: 1
        }
      }
    }
  },

  [uiActions.TOGGLE_ALL]: (draft, { entityKind, entityListName, entityIds }, state) => {
    let data = {}

    if (state.selectedEntity[entityKind] && state.selectedEntity[entityKind][entityListName].selected == 'ALL') {
      data = {
        selectedCount: 0,
      }
    } else {
      data = {
        selectedCount: entityIds.length,
        selected: 'ALL'
      }

      entityIds.forEach(entityId => {
        data[entityId] = true
      })
    }

    draft.selectedEntity[entityKind] = {[entityListName]: data}
  },

  '__default': (draft, payload, state) => {
    if (payload && payload.deletedRcEntity) {
      Object.keys(payload.deletedRcEntity).forEach(entityName => {
        const entityPascalCaseName = changeCase.pascal(entityName)
        const entityIds = payload.deletedRcEntity[entityName]

        const removedData = {}
        entityIds.forEach(entityId => {
          if (state.selectedEntity[entityPascalCaseName]) {
            Object.keys(state.selectedEntity[entityPascalCaseName]).forEach(listName => {

              if (state.selectedEntity[entityPascalCaseName][listName]) {
                const entity = state.selectedEntity[entityPascalCaseName][listName][entityId]

                if (entity) {
                  if (!removedData[listName]) removedData[listName] = 0
                  removedData[listName] += 1
                  draft.selectedEntity[entityPascalCaseName][listName][entityId] = false
                }
              }

            })

            Object.keys(removedData).forEach(listName => {
              const removedCount = removedData[listName]
              draft.selectedEntity[entityPascalCaseName][listName].selectedCount = state.selectedEntity[entityPascalCaseName][listName].selectedCount - removedCount
            })
          }
        })

      })
    }
  }
})
