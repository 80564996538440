import React from 'react'

// Reverb
import { styled, t, tt, tc } from 'volcano'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;

  border: 1px solid ${tc('primary9')};
  background-color: transparent;
  color: ${tt('button.color', 'colors.primary7')};

  ${p => p.primary && `border: 1px solid ${tc('primary6')(p)};`}
  ${p => p.primary && `color: ${tc('primary')(p)};`}

  &:first-of-type {
    border-radius:  4px 0 0 4px;
  }

  &:last-of-type {
    border-radius:  0 4px 4px 0;
  }

  padding: 3px 10px;

  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    background-color: ${tt('button.backgroundColorHover', 'colors.primary6')};
    color: ${tt('button.color', 'colors.primary8')};
    ${p => (p.secondary) && `background-color: transparent;`}
    ${p => (p.primary) && `background-color: ${tt('button.backgroundColor', 'colors.primary6')(p)};`}
  }

  ${p => p.secondary && `cursor: default;`}

  ${p => p.primary && `cursor: default;`}

  height: 28px;
`

// const Button = styled.button`
//   display: flex;
//   flex-direction: row;

//   background-color: ${tt('button.backgroundColor', 'colors.primary')};
//   color: ${tt('button.color', 'colors.primary9')};
//   border: none;

//   ${p => p.primary && `background-color: ${tc('primary6')(p)};`}

//   &:first-child {
//     border-radius:  4px 0 0 4px;
//   }

//   &:last-child {
//     border-radius:  0 4px 4px 0;
//   }

//   padding: 3px 10px;

//   cursor: pointer;

//   transition: all 0.2s ease;

//   &:hover {
//     background-color: ${tt('button.backgroundColorHover', 'colors.primary6')};
//     ${p => (p.secondary) && `background-color: ${tt('button.backgroundColor', 'colors.primary')(p)};`}
//     ${p => (p.primary) && `background-color: ${tt('button.backgroundColor', 'colors.primary6')(p)};`}
//   }

//   // ${p => p.secondary && `background-color: ${tt('button.backgroundColorSecondary', 'colors.light')(p)};`}
//   // ${p => p.secondary && `color: ${tt('button.backgroundColorSecondaryText', 'colors.dark')(p)};`}
//   ${p => p.secondary && `cursor: default;`}

//   ${p => p.primary && `cursor: default;`}
// `


const BetweenButton = () => <Button children='...' secondary />

const Pagination = ({
  currentDx, totalCount, itemPerPage, range = 3, onPageChange
}) => {
  if (currentDx === null || totalCount == null || itemPerPage == null) return null
  const pageCount = totalCount >= itemPerPage ? Math.floor(totalCount/itemPerPage) + 1 : 1
  const currentPage = currentDx >= itemPerPage ? Math.floor(currentDx / itemPerPage) + 1 : 1

  if (pageCount === 1) return null

  console.log(currentDx, totalCount, itemPerPage, range, pageCount, currentPage)

  const renderedPages = [...Array(range * 2 + 1).keys()]
    .map(i => currentPage - range + i)
    .filter(i => i > 0 && i <= pageCount)

    const showStart = currentPage - range > 1
    const showEnd = currentPage + range < pageCount

    return (
      <Wrapper>
        {showStart && (
          [
            <Button children={1} onClick={() => onPageChange(1)} />,
            <BetweenButton />
          ]
        )}
        {renderedPages.map(page => (
          <Button 
            key={page}
            onClick={() => onPageChange(page)} 
            children={page}
            primary={currentPage === page}
          />
        ))}
        {showEnd && (
          [
            <BetweenButton />, 
            <Button children={pageCount} onClick={() => onPageChange(pageCount)} />
          ]
        )}
      </Wrapper>
    )
}

export default Pagination
