import { useState, useEffect, useReducer, useRef, useCallback } from 'react';
import produce from 'immer'

const glb = global || window

export const createRReducer = actionHandlers => (state, action) => (
  produce(state, draft => {
    // console.log(action.kind, action.payload)
    actionHandlers[action.kind](draft, action.payload === undefined ? {} : action.payload, state, action)
  })
)

export const dataFetchReducer = createRReducer({
  FETCH_INIT: draft => {
    draft.isLoading = true
    draft.isError = false
    draft.error = null
  },

  FETCH_SUCCESS: draft => {
    draft.isLoading = false
    draft.isError = false
    draft.isReady = true
    draft.error = null
    draft.filters = null
    draft.opts = null
  },

  FETCH_FAILURE: (draft, payload) => {
    draft.isLoading = false
    draft.isError = true
    draft.error = payload
  },

  REFRESH: (draft, payload) => {
    draft.key += 1
    if (payload.filters) draft.filters = payload.filters
    if (payload.opts) draft.opts = payload.opts
  }
})

// export const useInterval = (callback, delay) => {
//   const savedCallback = useRef()

//   useEffect(() => {
//     savedCallback.current = callback
//   }, [callback]);

//   useEffect(() => {
//     function tick() {
//       savedCallback.current()
//     }
//     if (delay !== null && delay !== undefined) {
//       let id = glb.setInterval(tick, delay)
//       return () => glb.clearInterval(id)
//     }
//   }, [delay]);
// }

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  const currentId = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current()
      currentId.current = glb.setTimeout(tick, delay)
    }
    if (delay !== null && delay !== undefined) {
      currentId.current = glb.setTimeout(tick, delay)
      return () => glb.clearTimeout(currentId.current)
    }
  }, [delay]);
}