// Vendor
import { normalize, schema } from 'normalizr'
import * as changeCase from 'volcano/util/text'

const createEntitySchemas = (entityClasses) => {
  const entitySchemasByName = {}
  const entitySchemasBySnakeCaseName = {}

  // Define entity schemas
  Object.keys(entityClasses).forEach(entityName => {
    const entity = entityClasses[entityName]
    const entityCamelCaseName = changeCase.camel(entityName)
    const entitySnakeCaseName = changeCase.snakeCase(entityName)

    const entitySchema = new schema.Entity(entityCamelCaseName, {})
    const entityArraySchema = {
      items: [ entitySchema ],
    }
    entitySchemasByName[entityCamelCaseName] = entitySchema
    entitySchemasByName[`${entityCamelCaseName}List`] = entityArraySchema
    entitySchemasBySnakeCaseName[entitySnakeCaseName] = entitySchema
    entitySchemasBySnakeCaseName[`${entitySnakeCaseName}_list`] = entityArraySchema
  })

  // Define relationships
  Object.keys(entityClasses).forEach(entityName => {
    const entity = entityClasses[entityName]

    if (!entity.rcr_relationships || entity.rcr_relationships.length == 0) {
      return
    }

    const entityCamelCaseName = changeCase.camel(entityName)
    const entitySnakeCaseName = changeCase.snakeCase(entityName)
    const entitySchema = entitySchemasByName[entityCamelCaseName]

    entity.rcr_relationships.forEach(relationship => {
      const relatedEntityName = relationship.related_cls
      const isSingular = relationship.singular
      const isUnique = relationship.unique
      const backref = relationship.backref
      const attrName = relationship.attr_name
      const backrefPrefix = relationship.backref_prefix ? `${relationship.backref_prefix}_` : ''
      const backrefSuffix = relationship.backref_suffix ? `_${relationship.backref_suffix}` : ''
      const hasBackref = relationship.has_backref

      const relatedEntitySnakeCaseName = changeCase.snakeCase(relatedEntityName)
      const relatedEntityCamelCaseName = changeCase.camel(relatedEntityName)

      const relatedEntitySchema = entitySchemasByName[relatedEntityCamelCaseName]
      if (!relatedEntitySchema) {
        // throw new Error(`CANT_FIND_RELATED_ENTITY_SCHEMA: ${relatedEntityCamelCaseName}`)
        return
      }

      if (attrName.slice(-5) == '_list') {
        // reverse defined schema
        entitySchema.define({
          [`${attrName}`]: [ relatedEntitySchema ]
        })
      } else {
        entitySchema.define({
          [`${attrName}`]: relatedEntitySchema
        })
      }

      if (hasBackref) {
        if (isSingular) {
          relatedEntitySchema.define({
            [`${backrefPrefix}${entitySnakeCaseName}${backrefSuffix}`]: entitySchema
          })
        } else {
          relatedEntitySchema.define({
            [`${backrefPrefix}${entitySnakeCaseName}${backrefSuffix}_list`]: [ entitySchema ]
          })
        }
      }

    })
  })

  return {
    entitySchemasBySnakeCaseName,
    entitySchemasByName
  }
}

export default createEntitySchemas