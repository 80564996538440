import React from 'react'

// Volcano
import Box from './Box'


const Image = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="img"
    {...props}
    __themeKey="images"
    __css={{
      maxWidth: '100%',
      height: 'auto',
    }}
  />
))

export default Image