import React from 'react'

// Vendor
import { Provider } from 'react-redux'

// Volcano
import Volcano from 'volcano'

// Runic
import Director from 'runic/director'
import { RunicContext } from 'runic/context'

// Runic-Aura
import { Router } from 'runic-aura/router'
import RunicAura from 'runic-aura/systems/runic-aura'
import RunicIndex from 'runic-aura/apps/core/pages/RunicIndex'

import core from 'runic/systems/core'
import authentication from 'runic/systems/authentication'
import model from 'runic/systems/model'
import entity from 'runic/systems/entity'
import action from 'runic/systems/action'
import ui from 'runic/systems/ui'

const defaultSystems = [
  core,
  authentication,
  model,
  entity,
  action,
  ui
]

// import coreApp from 'runic/apps/core'
import uiTreeApp from 'runic-aura/apps/uitree'
import modelApp from 'runic-aura/apps/model'
import standardDomainApp from 'runic-aura/apps/domain/standard-domain'
// import modelDomainApp from 'runic/apps/domain/model-domain'

// import tenantApp from 'elements/rc_app/tenant'

const extraApps = [
  standardDomainApp,
  uiTreeApp,
  // tenantApp,
  // modelDomainApp
]

export const rncuiConfig = {
  component: {
    RenoaSidebar: {
      extended: true
    }
  }
}

const RunicApp = props => {
  const { store, theme, globalCss, uiConfig, apps, systems, storeCreator, config, homeComponent } = props
  const [director, directorSet] = React.useState(() => new Director())
  const storeRef = React.useRef()
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    // director.registerApp(coreApp)
    director.registerApp(modelApp)
    director.registerSystem(RunicAura)

    defaultSystems.forEach(system => director.registerSystem(system))

    extraApps.forEach(app => director.registerApp(app))
    apps?.forEach(app => director.registerApp(app))
    systems?.forEach(system => director.registerSystem(system))
    const unsubscribe = director.subscribe('update', directorSet)
    storeRef.current = storeCreator(director)
    setIsReady(true)
    return () => unsubscribe()
  }, [])

  if (!isReady) return null

  return (
    <RunicContext.Provider value={director}>
      <Provider store={storeRef.current}>
        <Router>
          <Volcano theme={theme} globalCss={globalCss}>
            <RunicIndex uiConfig={uiConfig || rncuiConfig} runicConfig={config} homeComponent={props.homeComponent} />
          </Volcano>
        </Router>
      </Provider>
    </RunicContext.Provider>
  )

}

export default RunicApp
