import React from 'react';

// Vendor
import {
  Route,
  Switch,
  useMatch
} from 'runic-aura/router'

// Self
import AuthenticationLogin from './AuthenticationLogin'
import AuthenticationLogout from './AuthenticationLogout'
// import AuthenticationForgot from './AuthenticationForgot'
// import AuthenticationReset from './AuthenticationReset'
// import AuthenticationEmailVerify from './AuthenticationEmailVerify'


const AuthenticationBase = props => {
  const match = useMatch()

  return(
    <Switch>
      <Route exact path={`${match.url}/login`} component={AuthenticationLogin}/>
      <Route exact path={`${match.url}/login/:rcTenantCode`} component={AuthenticationLogin}/>
      <Route exact path={`${match.url}/logout`} component={AuthenticationLogout}/>
      {/* <Route exact path={`${match.url}/login/:rcTenantId`} component={AuthenticationLogin}/>
      <Route exact path={`${match.url}/forgot`} component={AuthenticationForgot}/>
      <Route exact path={`${match.url}/reset/:token`} component={AuthenticationReset}/>
      <Route exact path={`${match.url}/verify/:token`} component={AuthenticationEmailVerify}/> */}
    </Switch>
  )
}

export default AuthenticationBase
