import React from 'react'

// Vendor
import usePopper from 'volcano/hooks/usePopper'

// Reverb
import Card from 'volcano/components/Card'
import { styled, t, tt, tc, Box } from 'volcano'

import useOnClickOutside from 'volcano/hooks/useOnClickOutside'
import useEventListener from 'volcano/hooks/useEventListener'
import keycode from 'keycode'

const useMenu = ({ placement, openOnHover, ...rest }) => {
  const referenceNode = React.useRef(null)
  const popperNode = React.useRef(null)
  const arrowNode = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const { style, scheduleUpdate, arrowStyle } = usePopper({
    referenceNode, popperNode, arrowNode, isOpen, placement, modifiers: {
      flip: {
        behavior: ['left', 'right', 'top', 'bottom']
      },
      // preventOverflow: { enabled: false },
      // positionFixed: true,
      preventOverflow: {
        boundariesElement: 'window'
      },
      // computeStyle: {
      //   gpuAcceleration: false
      // }
    }, ...rest
  })

  const toggleIsOpen = React.useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen((curr) => !curr)
    scheduleUpdate && scheduleUpdate()
    setTimeout(() => scheduleUpdate && scheduleUpdate(), 100)
  }, [scheduleUpdate])

  React.useEffect(() => {
    const interval = setInterval(() => scheduleUpdate && scheduleUpdate(), 1000)
    return () => clearInterval(interval)
  }, [scheduleUpdate])

  const close = React.useCallback(() => setIsOpen(false), [])
  const handleKeyPress = React.useCallback((e) => e.keyCode === keycode('Esc') && close(), [])

  useOnClickOutside(popperNode, close, true)
  useEventListener('keydown', handleKeyPress)

  const anchorProps = {
    onClick: toggleIsOpen,
    ref: referenceNode
  }

  if (openOnHover) {
    anchorProps.onMouseEnter = () => setIsOpen(true)
    anchorProps.onMouseLeave = () => setIsOpen(false)
  }
  const menuProps = {
    isOpen,
    ref: popperNode,
    style: {
      ...style,
      zIndex: 9999
    },
  }

  const [arrowPlacement] = placement.split("-");
  const transformMap = {
    top: "rotateZ(180deg)",
    right: "rotateZ(-90deg)",
    bottom: "rotateZ(360deg)",
    left: "rotateZ(90deg)"

    // right: "rotateZ(180deg)",
    // top: "rotateZ(-90deg)",
    // left: "rotateZ(360deg)",
    // bottom: "rotateZ(90deg)"
  };

  const arrowProps = {
    ref: arrowNode,
    style: {
      ...arrowStyle,
      position: 'absolute',
      display: isOpen ? 'block': 'none',
      transform: transformMap[arrowPlacement],
      [arrowPlacement]: '100%',
      width: '1em',
      height: '1em',
    },
  }

  return [anchorProps, menuProps, arrowProps, close]
}

const Svg = styled.svg`
  .stroke {
    fill: ${tc('light9')}
  }

  .fill {
    fill: ${tc('light9')}
  }
`

const MenuContent = React.forwardRef(({ isOpen, children, arrowProps, ...rest }, ref) => {
  return (
    <div {...rest} ref={ref} id='popoverWrapper'>
      <div {...arrowProps}>
      <Svg viewBox="0 0 30 30">
          <path
            className="stroke"
            d="M23.7,27.1L17,19.9C16.5,19.3,15.8,19,15,19s-1.6,0.3-2.1,0.9l-6.6,7.2C5.3,28.1,3.4,29,2,29h26
        C26.7,29,24.6,28.1,23.7,27.1z"
          />
          <path
            className="fill"
            d="M23,27.8c1.1,1.2,3.4,2.2,5,2.2h2H0h2c1.7,0,3.9-1,5-2.2l6.6-7.2c0.7-0.8,2-0.8,2.7,0L23,27.8L23,27.8z"
          />
        </Svg>
      </div>
      {isOpen && <Card p={0} minWidth={400}>
        {children}
      </Card>}
    </div>
  )
})


import ReactDOM from 'react-dom'
import { ModalContext } from '../hooks/useModal'
const Modal = props => {
  const modalRoot = process.browser ? document.getElementById('modal') : null
  const parent = React.useContext(ModalContext)
  const [elem] = React.useState(() => document.createElement('div'))
  React.useEffect(() => {
    if (parent) parent.appendChild(elem)
    else modalRoot.appendChild(elem)

    return () => {
      if (parent) parent.removeChild(elem)
      else modalRoot.removeChild(elem)
    }
  }, [])

  const content = (
    <ModalContext.Provider value={elem}>
      {props.children}
    </ModalContext.Provider>
  )
  return ReactDOM.createPortal(
    content,
    elem,
  )
}

export const Menu = React.forwardRef((props, ref) => {
  const { anchor, children, placement = 'bottom', ...rest } = props

  const [anchorProps, menuProps, arrowProps, close] = useMenu({ placement, ...rest })

  React.useImperativeHandle(ref, () => ({
    close
  }))

  if (!process.browser) return null

  return (
    <>
      {/* <div {...anchorProps}>{anchor}</div> */}
      {React.cloneElement(anchor, anchorProps)}
      <Modal>
      <MenuContent {...menuProps} arrowProps={arrowProps}>
        {children}
      </MenuContent>
      </Modal>
    </>
  )
})

export const BareMenu = React.forwardRef((props, ref) => {
  const { anchor, children, placement = 'bottom', width, ...rest } = props

  const [anchorProps, menuProps, arrowProps, close] = useMenu({ placement, ...rest })

  React.useImperativeHandle(ref, () => ({
    close
  }))

  if (!process.browser) return null

  return (
    <>
      {/* <div {...anchorProps}>{anchor}</div> */}
      {/* {React.cloneElement(anchor, anchorProps)} */}
      <div {...anchorProps}>
        {anchor}
      <Modal>
        <BareMenuContent {...menuProps} arrowProps={arrowProps} width={width}>
          {children}
        </BareMenuContent>
      </Modal>
      </div>
    </>
  )
})

const BareMenuContent = React.forwardRef(({ isOpen, children, arrowProps, width = 250, ...rest }, ref) => {
  return (
    <div {...rest} ref={ref} id='popoverWrapper'>
      <div {...arrowProps}>
      <Svg viewBox="0 0 30 30">
          <path
            className="stroke"
            d="M23.7,27.1L17,19.9C16.5,19.3,15.8,19,15,19s-1.6,0.3-2.1,0.9l-6.6,7.2C5.3,28.1,3.4,29,2,29h26
        C26.7,29,24.6,28.1,23.7,27.1z"
          />
          <path
            className="fill"
            d="M23,27.8c1.1,1.2,3.4,2.2,5,2.2h2H0h2c1.7,0,3.9-1,5-2.2l6.6-7.2c0.7-0.8,2-0.8,2.7,0L23,27.8L23,27.8z"
          />
        </Svg>
      </div>
      {isOpen && <Box p={0} width={width} backgroundColor='#FFF' sx={{
        border: '1px solid #e6e6e6',
        borderTop: '2px solid #777',
        marginTop: 18
      }}>
        {children}
      </Box>}
    </div>
  )
})

export const MenuItem = props => (
  <Box
    __css={{
      "&:hover": {
        bg: 'light',
      },
      p: 3,
      color: 'primary',
      cursor: 'pointer'
    }}
    {...props}
  />
)