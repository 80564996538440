import React from 'react'

// Vendor
import ScaleLoader from 'react-spinners/ScaleLoader'

// Reverb
import { useTheme, tc } from 'volcano'


export const Loading = ({
  color = 'primary'
}) => {
  const theme = useTheme()

  return (
    <ScaleLoader
        loading={true}
        css={{paddingTop: 3, marginBottom: -3}}
        height={10}
        width={2}
        color={tc(color)({theme})} />
  )
}