// Runic
import { createReducer, setDraft } from 'runic/core/redux'

import authenticationActions from './actions'
import coreActions from 'runic/systems/core/actions'


const defaultState = {
  isAuthenticated: false,
  isAuthenticating: false,
  authenticationError: null,
  rcUserId: null,
  rcUser: {},
  isSynced: null
}

export default createReducer(defaultState, {
  [authenticationActions.RCR_LOGIN]: {
    success: (draft, { rc_user }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: true,
        authenticationError: null,
        rcUserId: rc_user.id,
        rcUser: rc_user,
        isSynced: true,
      })
    },

    error: (draft, payload) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: false,
        authenticationError: payload,
        rcUserId: null,
        isSynced: true,
      })
    },

    start: (draft) => draft.isAuthenticating = true,
  },

  [coreActions.RCR_RIVA_SETUP]: {
    success: (draft, { rcEntity }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: true,
        authenticationError: null,
        rcUserId: rcEntity.result.rc_user,
      })
    },

    error: (draft, { payload }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: false,
        authenticationError: payload,
        rcUserId: null
      })
    },

    start: (draft) => draft.isAuthenticating = true,
  },

  [coreActions.bootstrap]: {
    success: (draft, { rcEntity, isAuthenticated }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: isAuthenticated,
        authenticationError: null,
        rcUserId: rcEntity && rcEntity.result.rc_user
      })
    },

    error: (draft, { payload }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: false,
        authenticationError: payload,
        rcUserId: null
      })
    },

    start: (draft) => draft.isAuthenticating = true,
  },

  [authenticationActions.SYNC_LOGIN_STATE]: {
    success: (draft, { isAuthenticated, rcUser }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: isAuthenticated,
        authenticationError: null,
        rcUser: rcUser,
        isSynced: true,
      })
    },

    error: (draft, { payload }) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: false,
        authenticationError: payload,
        rcUserId: null,
        isSynced: true,
      })
    },

    // start: (draft) => draft.isAuthenticating = true,
  },

  [authenticationActions.RCR_LOGOUT]: {
    success: (draft) => {
      setDraft(draft, {
        isAuthenticating: false,
        isAuthenticated: false,
        authenticationError: null,
        rcUserId: null,
        rcUser: null,
        isSynced: true,
      })
    },

    // error: (draft, payload) => {
    //   setDraft(draft, {
    //     isAuthenticating: false,
    //     isAuthenticated: false,
    //     authenticationError: payload,
    //     rcUserId: null
    //   })
    // }
  },
})

