import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

import { getActiveRcUser } from 'runic/systems/core/selectors'

const useAppState = () => {
  const user = useSelector(getActiveRcUser)
  const tenant = useSelector(state => state.core.activeRcTenant)
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)

  return ({
    user,
    tenant,
    isAuthenticated
  })
}

export default useAppState