import RunicAppUnit from './pages/RunicAppUnit'

export default {
  name: 'runic-aura.uitree',
  directory: {
    runicAura: {
      unitHandlers: {
        r2: RunicAppUnit
      }
    }
  },
}