import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'
import Button from 'volcano/components/Button'
import Card from 'volcano/components/Card'

// RunicAura
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import { useHistory } from 'runic-aura/router'

// Runic
import authenticationActions from 'runic/systems/authentication/actions'
import useDirector from 'runic/hooks/useDirector'


const AuthenticationLogin = props => {
  const rcTenantCode = props.match.params && props.match.params.rcTenantCode
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const history = useHistory()
  const director = useDirector()

  const [formProps, isSubmitting] = useRunicForm(authenticationActions.rcrLogin, {
    action: (data, {setSubmitting}) => {
      const loginData = {...data}
      if (!loginData.rcTenantCode) {
        if (props.rcTenantId) loginData.rcTenantId = parseInt(rcTenantId)
        else if (rcTenantCode) loginData.rcTenantCode = rcTenantCode
        else {loginData.rcTenantId = 1}
      }
      return [
        loginData,
        // {
        //   success: () => history.push(`/home`),
        // }
      ]
    }
  })

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push(`/home`)
    }
  }, [isAuthenticated])

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={1/4} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
          {director.rConfig.multiTenant && !rcTenantCode && <TextField name="rcTenantCode" label='Hesap' tabIndex={1} required/>}
          <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus={!director.rConfig.multiTenant} />
          <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
          <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
          <Button label='Giriş' fullWidth tabIndex={4} submit working={isSubmitting} />
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationLogin
