import React from 'react'

// Runic
import { Route, Switch } from 'runic-aura/router'
import AuthenticationCheck from 'runic-aura/apps/authentication/components/AuthenticationCheck'

import RunicTenantSelector from './RunicTenantSelector'
import RunicTenantIndex from './RunicTenantIndex'


const RunicHome = props => {
  return (
    <AuthenticationCheck>
      <Switch>
        <Route path="/home">
          <RunicTenantSelector />
        </Route>
        <Route path="/:rcTenantId/">
          <RunicTenantIndex />
        </Route>
      </Switch>
    </AuthenticationCheck>
  )
}

export default RunicHome
