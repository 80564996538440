import React from 'react'

// Vendor
import { props as systemProps } from '@styled-system/should-forward-prop'

// Reverb
import { Box, Flex } from '../index'

const volcanoProps = [
  ...systemProps,
  'sx',
  'variant',
]

const PRE = new RegExp(`^(${volcanoProps.join('|')})$`)
const MRE = /^m[trblxy]?$/

const getProps = (test) => (props) => {
  const next = {}
  for (const key in props) {
    if (test(key || '')) next[key] = props[key]
  }
  return next
}

const getSystemProps = getProps(k => PRE.test(k))
const getMarginProps = getProps(k => MRE.test(k))
const omitMarginProps = getProps(k => !MRE.test(k))

const SVG = ({ size = 24, ...props }) =>
  <Box
    as='svg'
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill='currentcolor'
    {...props}
  />

export const Label = React.forwardRef((props, ref) =>
  <Flex
    ref={ref}
    as='label'
    tx='forms'
    variant='label'
    {...props}
    __css={{
      width: '100%',
    }}
  />
)



const CheckboxChecked = props =>
  <SVG {...props}>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
  </SVG>

const CheckboxUnchecked = props =>
  <SVG {...props}>
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
  </SVG>

const CheckboxIcon = props =>
  <>
    <CheckboxChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        }
      }}
    />
    <CheckboxUnchecked
      {...props}
      __css={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        }
      }}
    />
  </>

export const Checkbox = React.forwardRef(({
  className,
  sx,
  variant = 'checkbox',
  ...props
}, ref) =>
  <Box>
    <Box
      ref={ref}
      as='input'
      type='checkbox'
      {...props}
      sx={{
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    />
    <Box
      as={CheckboxIcon}
      aria-hidden='true'
      tx='forms'
      variant={variant}
      className={className}
      sx={sx}
      {...getSystemProps(props)}
      __css={{
        marginTop: "-3px",
        mr: 2,
        borderRadius: 4,
        color: 'gray',
        'input:checked ~ &': {
          color: 'primary',
        },
        'input:focus ~ &': {
          color: 'primary',
          bg: 'highlight',
        },
      }}
    />
  </Box>
)
