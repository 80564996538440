import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'

// Reverb
import { Flex, Box } from 'volcano'
import Text from 'volcano/components/Text'
import Card from 'volcano/components/Card'

// Rncui
import { Switch, Route, useMatch } from 'runic-aura/router'
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

import ModelDetailCard from 'runic-aura/apps/model/components/ModelDetailCard'
import ModelListView from 'runic-aura/apps/model/components/ModelListView'

// Runic
import { useDetailView } from 'runic/hooks/model'
import { useSelector } from 'react-redux'


const FulfilmentDetail = ({
  // unit, profile, backUrl
}) => {
  const match = useMatch()
  useRncActivate('detail', { identifier: 'RnxFulfilment.RnxFulfilment', hasSubLayout: true }, true)

  const pageProps = null
  const modelName = 'RnxFulfilment'

  return (
    <Flex pl={70} pr={40} py={10}>
      <Box width={1}>
        <Switch>
          <Route path={`${match.path}`}>
          <DetailComponent/>
          </Route>
        </Switch>
      </Box>
    </Flex>
  )
}

const DetailComponent = ({
  
}) => {
  const modelName = 'RnxFulfilment'
  const match = useMatch()

  const dvInstance = useDetailView({modelName, value: match.params.unitId})
  if (!dvInstance.entity || !dvInstance.view) return null
  console.log(dvInstance)
  return (
    <Box>
      <Flex>
        <Box>
          {/* {backUrl && <Link to={backUrl}><Icon name='arrow-left' color='primary' />Geri Dön</Link>} */}
          <Text fontWeight='bold' textCase='title' id={`label.${changeCase.snake(dvInstance.modelData.name)}`}/>
          <Box mb={2}/>
        </Box>
      </Flex>

      <ModelDetailCard entity={dvInstance.entity} modelData={dvInstance.modelData} view={dvInstance.view} noWrapper={true} fieldKind='primary'/>
      <Box mt={3}>
        <Location rnxFulfilment={dvInstance.entity}/>
      </Box>
    </Box>
  )
}


const Location = ({
  rnxFulfilment
}) => {
  const dvInstance = useDetailView({modelName: 'RnxLocation', value: rnxFulfilment.rnx_location_id})
  const rnxContact = useSelector(state => state.entity[changeCase.camel('RnxContact')].entity[rnxFulfilment.rnx_contact_id])
  if (!dvInstance.entity || !dvInstance.view) return null

  console.log(rnxContact)
  return (
    <Flex>

      <Box>
      <Text fontWeight='bold' textCase='title' id={'Bilgiler'}/>
      <Card mt={2}>
        <Box>
        Adres: {dvInstance.entity.text} / {dvInstance.entity.state} / {dvInstance.entity.city}
        </Box>

        <Box mt={3}>
        Telefon: {rnxContact.phone_number}
        </Box>

        <Box mt={3}>
        E-Posta: {rnxContact.email}
        </Box>
      </Card>
      </Box>

      <Box ml={3} flex='1 1 0'>
      <ModelListView modelName={'RnxProductSetProduct'} profileName={'default'} scope={{listViewInfo: {name: 'sublist'}}} tableOptions={{hasMenu: false}} filters={{
        'RnxProductSetProduct.rnx_product_set.id': rnxFulfilment.rnx_product_set_id
      }}/>
      </Box>
    </Flex>
  )
}

export default FulfilmentDetail
