import React from 'react'

// Vendor
import { createLocation } from "history"

// Rncui
import { LocationRouterContext, ImmutableRouterContext } from '../RncRouterContext'


const resolveToLocation = (to, currentLocation) =>
  typeof to === "function" ? to(currentLocation) : to

const normalizeToLocation = (to, currentLocation) => {
  return typeof to === "string"
    ? createLocation(to, null, null, currentLocation)
    : to
};

const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const RncLinkAnchor = React.forwardRef((props, ref) => {
  const { navigate, onClick, ...rest } = props
  const { target } = rest

  const newProps = {
    ...rest,
    onClick: event => {
      try {
        if (onClick) onClick(event)
      } catch (ex) {
        event.preventDefault()
        throw ex
      }

      if (
        !event.defaultPrevented &&
        event.button === 0 &&
        (!target || target === "_self") &&
        !isModifiedEvent(event)
      ) {
        event.preventDefault()
        navigate()
      }
    }
  }

  return <a {...newProps} ref={ref} />
})

const RncLink = React.forwardRef(
  ({
    component = RncLinkAnchor,
    replace,
    to,
    ...rest
  },
    ref
  ) => {
    const { history } = React.useContext(ImmutableRouterContext)
    const locationFromContext = React.useContext(LocationRouterContext)

    const location = normalizeToLocation(
      resolveToLocation(to, locationFromContext),
      locationFromContext,
    )

    const href = location ? history.createHref(location) : ""

    const newProps = {
      ...rest,
      href,
      ref,
      navigate() {
        const location = resolveToLocation(to, locationFromContext)
        const method = replace ? history.replace : history.push

        method(location)
      }
    }

    return React.createElement(component, newProps)
  }
);

export default RncLink