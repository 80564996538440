import React from 'react'

// Reverb
import Card from './Card'

import { styled, t, tt, tc } from 'volcano'

export const ContentHeader = styled.div`
  margin-bottom: 10px;
  position: relative;

  display: flex;
  align-items: center;

  justify-content: space-between;

  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: ${tc('primary')};

    border-radius: 4px;
  }

  height: 30px;
`

export const ContentPrimaryItems = styled.div`
  display: flex;
  float: right;
`

export const ContentSecondaryItems = styled.div`
  margin-left: 20px;
  display: inline-block;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 25px;
  margin-top: -5px;
  flex-grow: 1;
`

const NoCard = props => <div>{props.children}</div>

const Content = ({
  headerLocation = 'overCard', header, useCard = true, children
}) => {
  const Wrapper = useCard ? Card : NoCard

  if (headerLocation == 'overCard') {
    return (
      <>
        {header}
        <Wrapper>
          {children}
        </Wrapper>
      </>
    )
  } else {
    return (
      <Wrapper>
        {header}
        {children}
      </Wrapper>
    )
  }
}

export default Content