import React from 'react'

// Vendor
import * as yup from 'yup'

// Reverb
import { TextField, CheckboxField, DropdownField } from 'runic-aura/components/RunicField'
import Text from 'volcano/components/Text'

import ModelTypeahead from './ModelTypeahead'


const EntityFormField = ({
  entity,
  field,
  index
}) => {
  console.log('--', field)
  let schema
  if (!field.optional) schema = yup.string().required()

  const focus = index === 0
  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  }

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )

}

export default EntityFormField