// Vendor
import * as changeCase from 'volcano/util/text'

import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

import produce from 'immer'

// Riva
import modelActions from 'runic/systems/model/actions'
import coreActions from 'runic/systems/core/actions'


// FIXME: find a suitable place
const rMerge = (existing, incoming) => {
  const merged = { ...existing }

  Object.keys(incoming).forEach(incomingKey => {
    const incomingVar = incoming[incomingKey]
    const existingVar = merged[incomingKey]

    if (incomingVar === existingVar) {
      return
    }

    if (existingVar == null || existingVar == undefined) {
      merged[incomingKey] = incomingVar
      return
    }

    // if (incomingVar == null || incomingVar == undefined) {
    //   merged[incomingKey] = incomingVar
    //   return
    // }

    if (incomingVar == null) {
      merged[incomingKey] = incomingVar
      return
    } else if (existingVar && incomingVar == undefined) { // For partial updates.
      return
    }

    // Because isObject returns true for arrays, we need to check isArray first.
    const isArrIncomingVar = isArray(incomingVar)
    const isArrExistingVar = isArray(existingVar)

    if (isArrIncomingVar && isArrExistingVar) {
      // FIXME: remove the redundant clause if it's not needed when stabilized.
      if (incomingVar.length == 0) {
        merged[incomingKey] = incomingVar
        return
      } else {
        merged[incomingKey] = incomingVar
        return
      }
    }

    const isObjIncomingVar = isObject(incomingVar)
    const isObjExistingVar = isObject(existingVar)

    // FIXME: check if working for partial updates
    if (isObjExistingVar && isObjIncomingVar) {
      merged[incomingKey] = rMerge(existingVar, incomingVar)
      return
    }

    merged[incomingKey] = incomingVar

  })

  return merged
}

const defaultState = {}

const produceModelState = (modelData, state) => produce(state, draft => {
  Object.keys(modelData).forEach(modelName => {
    const model = modelData[modelName]
    const modelCamelCaseName = changeCase.camel(modelName)
    if (!state[modelCamelCaseName]) draft[modelCamelCaseName] = {
      view: {},
      list: {},
      entity: {},
      viewKeyMatch: {},
      entityKeyMatch: {}
    }
  })
})

const handleEntityStateAction = (state, action) => {
  return produce(state, draft => {
    if (action.payload && action.payload.rcEntity) {
      const { entities, result } = action.payload.rcEntity;

      // Handle entities
      Object.keys(entities).forEach(entityName => {
        const modelCamelCaseName = changeCase.camel(entityName)
        const entityDatas = entities[modelCamelCaseName]

        const mergedEntity = rMerge(state[entityName].entity, entityDatas)
        draft[modelCamelCaseName].entity = mergedEntity
      })

      if (action.payload._entity_fetch_key) {
        Object.keys(action.payload._entity_fetch_key).forEach(modelCamelCaseName => {
          draft[modelCamelCaseName].entityKeyMatch = rMerge(state[modelCamelCaseName].entityKeyMatch, action.payload._entity_fetch_key[modelCamelCaseName])
        })
      }

      // Handle views
      if (action.payload._view) {
        Object.keys(action.payload._view).forEach(entityName => {
          const modelCamelCaseName = changeCase.camel(entityName)
          const viewData = action.payload._view[entityName]
          const mergedEntity = rMerge(state[modelCamelCaseName].view, {[viewData.name]: viewData})

          draft[modelCamelCaseName].view = mergedEntity
          draft[modelCamelCaseName].viewKeyMatch[action.payload._viewKey || action.payload._fetchKey] = viewData.name
        })
      }

      // Handle lists
      Object.keys(result).forEach(resultName => {
        if (!result[resultName]) return

        const entitySnakeCaseName = resultName.replace('_list', '')
        const modelCamelCaseName = changeCase.camel(entitySnakeCaseName)

        const fetchKey = action.payload._fetchKey

        if (!draft[modelCamelCaseName]) {
          console.log('MODEL_NOT_FOUND', modelCamelCaseName)
          return
        }

        // if (state[modelCamelCaseName].list[fetchKey]) {
        //   console.log(state[modelCamelCaseName].list[fetchKey].items)
        // console.log(state[modelCamelCaseName].list[fetchKey].startIndex)
        // console.log(state[modelCamelCaseName].list[fetchKey].endIndex)
        // }

        // console.log(result[resultName].items)
        // console.log(result[resultName].startIndex)
        // console.log(result[resultName].endIndex)

        const startIndex = parseInt(result[resultName].startIndex)
        const endIndex = parseInt(result[resultName].endIndex)

        if (startIndex > 0){
          let items
          if (state[modelCamelCaseName].list[fetchKey]) {
            items = state[modelCamelCaseName].list[fetchKey].items || []
          } else {
            items = []
          }

          console.log(items)

          if (items.length < endIndex) {
            items[endIndex - 1] = null
          }

          console.log(endIndex - startIndex, result[resultName].items)
          items.splice(startIndex, endIndex - startIndex, ...result[resultName].items)
          console.log(items)

          draft[modelCamelCaseName].list[fetchKey] = {
            items: items,
            count: result[resultName].count,
            version: result[resultName].version,
            endIndex: result[resultName].endIndex,
            startIndex: result[resultName].startIndex,
          }
        } else {
          draft[modelCamelCaseName].list[fetchKey] = {
            items: result[resultName].items,
            count: result[resultName].count,
            version: result[resultName].version,
            endIndex: result[resultName].endIndex,
            startIndex: result[resultName].startIndex,
          }
        }

        if (result[resultName].activeListViewName) {
          const activeListViewName = result[resultName].activeListViewName

          draft[modelCamelCaseName].view[activeListViewName] = result[resultName].activeListView
          draft[modelCamelCaseName].viewKeyMatch[fetchKey] = activeListViewName
        }
      })
    }

    if (action.payload && action.payload.deletedRcEntity) {
      Object.keys(action.payload.deletedRcEntity).forEach(entityName => {
        const modelCamelCaseName = changeCase.camel(entityName)
        const entityIds = action.payload.deletedRcEntity[entityName]

        const deletedIdData = {}
        entityIds.forEach(entityId => {
          if (state[modelCamelCaseName].entity[entityId]) draft[modelCamelCaseName].entity[entityId] = {_rcrArchived: true}

          Object.keys(state[modelCamelCaseName].list).forEach(listName => {
            if (state[modelCamelCaseName].list[listName].items) {
              const entityIndex = state.list[modelCamelCaseName][listName].items.indexOf(entityId)

              if (entityIndex != -1) {
                if (!deletedIdData[listName]) deletedIdData[listName] = []
                deletedIdData[listName].push(entityId)
              }
            }
          })
        })

        Object.keys(deletedIdData).forEach(listName => {
          const deletedIdList = deletedIdData[listName]

          draft[modelCamelCaseName].list[listName].items = state[modelCamelCaseName].list[listName].items.filter(item => deletedIdList.indexOf(item) == -1)
          draft[modelCamelCaseName].list[listName].count = state[modelCamelCaseName].list[listName].count - deletedIdList.length
        })

      })
    }
  })
}

export default (state = defaultState, action) => {
  if (action.type == modelActions.RCR_REGISTER_RECRAFT_MODELS) return produceModelState(action.payload.modelData, state)

  // FIXME
  else if (action.type == coreActions.RCR_RIVA_TENANT_SETUP && (action.meta.status == 'SUCCESS')) return produceModelState(action.payload.models, state)

  else if (action.type == coreActions.bootstrap && (action.meta.status == 'SUCCESS')) {
    const newState = produceModelState(action.payload.modelData, state)
    return handleEntityStateAction(newState, action)
  }

  else if (action && action.meta && action.meta.status == 'START') return state

  else if (action && action.meta && action.meta.status == 'SUCCESS') return handleEntityStateAction(state, action)

  else return state
}
