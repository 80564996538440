// Vendor
import * as changeCase from "change-case"
import isFunction from 'lodash/isFunction'
import produce from 'immer'

const ALLOWED_ACTION_CATEGORY_LIST = ['RCR_ARCHIVE', 'RCR_SOURCE', 'RCR_ACTION', 'CUSTOM', 'LOCAL']


export const createActions = (actions, namespace, baseComponent) => {
  const exported = {}

  Object.keys(actions).forEach(actionCategoryName => {
    if (!ALLOWED_ACTION_CATEGORY_LIST.find(actName => actName == actionCategoryName)) {
      throw new Error('INVALID_ACTION_CATEGORY')
    }
    const actionCategory = actions[actionCategoryName]

    Object.keys(actionCategory).forEach(actionBaseName => {
      const action = actionCategory[actionBaseName]

      const actionFunctionName = changeCase.camelCase(actionBaseName)
      const actionName = `${namespace}/${changeCase.camelCase(actionBaseName)}`

      exported[actionName] = actionName

      const _baseComponent = action.component || baseComponent

      if (actionCategoryName == 'RCR_ARCHIVE') {
        exported[actionFunctionName] = (payload, callbacks) => ({
          type: actionName,
          meta: {
            status: 'START',
            kind: actionCategoryName,
            component: _baseComponent,
            archive: action.archive
          },
          payload,
          callbacks,
        })
      } else if (actionCategoryName == 'RCR_SOURCE') {
        exported[actionFunctionName] = (payload, callbacks, sourceUrlPath) => ({
          type: actionName,
          meta: {
            status: 'START',
            kind: actionCategoryName,
            component: _baseComponent,
            archive: action.source || action.archive,
            sourceUrlPath: sourceUrlPath
          },
          payload,
          callbacks,
        })
      } else if (actionCategoryName == 'LOCAL') {
        exported[actionFunctionName] = (payload, callbacks) => ({
          type: actionName,
          meta: {
            kind: actionCategoryName,
          },
          payload,
          callbacks,
        })
      } else if (actionCategoryName == 'CUSTOM') {
        exported[actionFunctionName] = (payload, callbacks) => ({
          type: actionName,
          meta: {
            status: action.hasProgress && 'START',
            kind: actionCategoryName,
            component: _baseComponent,
            actionKind: action.actionKind
          },
          payload,
          callbacks,
        })
      } else {
        exported[actionFunctionName] = (payload, callbacks) => ({
          type: actionName,
          meta: {
            status: 'START',
            kind: actionCategoryName,
            component: _baseComponent,
            actionKind: action.actionKind
          },
          payload,
          callbacks,
        })
      }

      exported[actionFunctionName].actionType = actionName
      exported[actionFunctionName].toString = () => actionName

      const actionConstantName = changeCase.constantCase(actionBaseName)
      exported[actionConstantName] = actionName
      // exported[actionConstantName].actionType = actionName
      // exported[actionConstantName].toString = () => actionName
    })
  })

  return exported
}

export const createReducer = (defaultState, actionHandlers) => {
  return (state = defaultState, action) => {
    const actionHandler = actionHandlers[action.type]
    const defaultActionHandler = actionHandlers['__default']

    if (!actionHandler && !defaultActionHandler) {
      return state
    }

    return produce(state, draft => {
      let result
      if (actionHandler && action.meta && action.meta.kind == 'LOCAL') {
        result = actionHandler(draft, action.payload || {}, state, action)
      } else if (actionHandler && actionHandler.success && action.meta && action.meta.status == 'SUCCESS') {
        result = actionHandler.success(draft, action.payload || {}, state, action)
      } else if (actionHandler && actionHandler.error && action.meta && action.meta.status == 'ERROR') {
        result = actionHandler.error(draft, action.payload || {}, state, action)
      } else if (actionHandler && actionHandler.start && action.meta && action.meta.status == 'START') {
        result = actionHandler.start(draft, action.payload || {}, state, action)
      } else if (actionHandler && isFunction(actionHandler) && action.meta && action.meta.status == 'START') {
        result = actionHandler(draft, action.payload || {}, state, action)
      } else if (defaultActionHandler && isFunction(defaultActionHandler)) {
        result = defaultActionHandler(draft, action.payload || {}, state, action)
      } else {
        return draft
      }

    })
  }
}

export const setDraft = (draft, newState) => {
  Object.keys(newState).forEach(key => {
    draft[key] = newState[key]
  })
}