import React from 'react'

// Vendor
import * as _yup from 'yup'
export const yup = {}
Object.entries(_yup).forEach(([k, v]) => yup[k] = v)

import { useDispatch } from 'react-redux'
import { Form as RFForm } from 'react-final-form'
export { Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Axios from 'axios'


export const Form = ({
  validate,
  renderSuccess,
  initialValues,
  children,
  phase,
  handleSubmit,
  error
}) => {
  return (
    <RFForm
    onSubmit={handleSubmit}
    mutators={{
      ...arrayMutators
    }}
    initialValues={initialValues}
    keepDirtyOnReinitialize={true}
    validate={validate}>
      {({handleSubmit, form}) => (
        <form onSubmit={handleSubmit}>
          {renderSuccess && phase === 'DONE' && renderSuccess()}
          {!(renderSuccess && phase === 'DONE') && children}
        </form>
      )}
    </RFForm>
  )
}

export default Form

// export const FormError = ({error}) => {
//   if (error) {
//     return error.kind ? (
//       <Alert mb={2} kind='warning'><Translation sentence>{`error.${error.kind}`}</Translation>.</Alert>
//     ) : (
//       <Alert mb={2} kind='warning'>Bir hata oluştu.</Alert>
//     )
//   } else {
//     return null
//   }
//   error && error.kind ? <Alert kind='warning'><Translation sentence>{`error.${error.kind}`}</Translation>.</Alert> : null
// }

export const useUrlForm = ({
  url,
  data,
  onSuccess,
  onError,
  getData,
  ...props
}) => {
  const [phase, setPhase] = React.useState('FORM')
  const [error, setError] = React.useState(null)
  const [result, setResult] = React.useState([])
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(async (values, form, callback) => {
    setSubmitting(true)
    let formData = {...values, ...data}
    if (getData) formData = {...formData, ...getData()}
    const response = await Axios.post(url, formData).catch(e => {
      setSubmitting(false)
      setError(e)
      onError?.(e)
    })
    if (response) {
      setSubmitting(false)
      setResult(response.data)
      onSuccess?.(response.data)
    }
  }, [])

  return ([{
    ...props,
    phase,
    error,
    handleSubmit
  }, isSubmitting])
}

export const useRunicForm = (
  actionCreator,
  callbacks = {}
) => {
  const [phase, setPhase] = React.useState('FORM')
  const [error, setError] = React.useState(null)
  const [result, setResult] = React.useState([])
  const [isSubmitting, setSubmitting] = React.useState(false)

  const dispatch = useDispatch()

  const handleSubmit = (data, options) => {
    let actionData = data
    let dataCallbacks = {}

    if (callbacks.action) {
      const [newActionData, newCallbacks] = callbacks.action(data, options)
      actionData = newActionData
      dataCallbacks = newCallbacks
    }
    setSubmitting(true)
    setError(null)
    return dispatch(actionCreator(actionData, {
      success: (payload) => {
        setPhase('DONE')
        setError(null)
        setResult(payload)
        setSubmitting(false)
        callbacks?.success?.(payload)
        dataCallbacks?.success?.(payload)
      },
      error: (payload) => {
        setPhase('ERROR')
        setError(payload)
        setResult(payload)
        setSubmitting(false)
        callbacks?.error?.(payload)
        dataCallbacks?.error?.(payload)
      }
    }))
  }

  return ([{
    // ...props,
    phase,
    error,
    handleSubmit
  }, isSubmitting])
}