import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'

// Reverb
import Card from 'volcano/components/Card'
import { ContentHeader, ContentPrimaryItems } from 'volcano/components/Content'
import Text from 'volcano/components/Text'
import Alert from 'volcano/components/Alert'
import Pagination from 'volcano/components/Pagination'

// Riva
import { useListView, useLocationFilter } from 'runic/hooks/model'
import ModelListViewTable from './ModelListViewTable'
import ModelActionGroup from './ModelActionGroup'


const ModelListViewHeader = React.memo(({ listView, modelData, headerText, refresh }) => (
  <div>
    <a onClick={refresh}>
      <Text textCase='title' fontWeight='semiBold'>{headerText || `label.${changeCase.snake(modelData.name)}_list`}</Text>
    </a>
    {/*{listView && listView.meta && <React.Fragment> | <ModelListViewSelector listView={listView}/></React.Fragment>}*/}
  </div>
))

const Error = ({ error }) => {
  if (error.status == 401) {
    return (
      <Alert kind='danger'>
        Bu listeye erişiminiz yok.
          </Alert>
    )
  } else {
    return (
      <Alert kind='warning'>
        Bir hata oluştu.
          </Alert>
    )
  }
}

const ModelListViewTableContent = ({
  lvInstance,
  filterInstance,
  options
}) => {
  if (!lvInstance.status.isReady) return null

  return (
    <ModelListViewTable lvInstance={lvInstance} filterInstance={filterInstance} options={options}/>
  )
}

const ModelListView = ({
  modelName,
  headerText,
  filters,
  scope,
  tableOptions
}) => {
  const filterInstance = useLocationFilter(modelName)
  const lvInstance = useListView(modelName, {scope, filters: {...filterInstance.locationFilters, ...filters}})
  const { displayState: { mode } } = lvInstance
  // console.log('lvInstance', lvInstance)
  if (!lvInstance.listView) return null

  let content
  if (mode == 'table') content = (
    <Card css={{ boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)" }} p={0}>
      {lvInstance.status.error ? (
        <Error error={lvInstance.status.error} />
      ) : (
          <ModelListViewTableContent lvInstance={lvInstance} filterInstance={filterInstance} options={tableOptions}/>
        )}
    </Card>
  )

  return (
    <>
      <ContentHeader>
        <ModelListViewHeader listView={lvInstance.listView} modelData={lvInstance.modelData} headerText={headerText} refresh={lvInstance.refresh} />
        <ContentPrimaryItems>
          <ModelActionGroup modelName={modelName} actionGroupKind='Create'/>
          <Pagination onPageChange={page => filterInstance.updateLocationFilters({dx: (page-1) * 50}, {mode: 'replace', keepDx: true})} currentDx={filterInstance.locationFilters.dx || 0} totalCount={lvInstance.listView && lvInstance.listView.list && lvInstance.listView.list.count} itemPerPage={50} />
        </ContentPrimaryItems>
      </ContentHeader>
    
      {content}
    </>
  )
}

export default ModelListView
