// Vendor
import * as changeCase from 'volcano/util/text'

// Runic
import { createReducer, setDraft } from 'runic/core/redux'

import modelActions from './actions'
import coreActions from 'runic/systems/core/actions'
import createEntitySchemas from 'runic/core/schema'
import createEntitySelectors from 'runic/core/selector'


const defaultState = {
  models: {},
  modelWatch: {},
  entitySchemasBySnakeCaseName: {},
  entitySelectors: null,
}

export default createReducer(defaultState, {
  [modelActions.RCR_REGISTER_RECRAFT_MODELS]: (draft, { modelData, entitySchemasBySnakeCaseName, entitySelectors }, state) => {
    draft.models = {...state.models, ...modelData}
    draft.entitySchemasBySnakeCaseName = {...state.entitySchemasBySnakeCaseName, ...entitySchemasBySnakeCaseName}
    draft.entitySelectors = {...state.entitySelectors, ...entitySelectors}
  },

  [coreActions.RCR_RIVA_TENANT_SETUP]: {
    success: (draft, { models }, state) => {
      const { entitySchemasBySnakeCaseName, entitySchemasByName } = createEntitySchemas(models)
      const entitySelectors = createEntitySelectors(entitySchemasByName, models)

      draft.models = {...state.models, ...models}
      draft.entitySchemasBySnakeCaseName = {...state.entitySchemasBySnakeCaseName, ...entitySchemasBySnakeCaseName}
      draft.entitySelectors = {...state.entitySelectors, ...entitySelectors}
    }
  },

  [modelActions.RCR_REGISTER_RECRAFT_SCHEMAS]: (draft, { entitySchemasBySnakeCaseName }, state) => {
    draft.entitySchemasBySnakeCaseName = {...state.entitySchemasBySnakeCaseName, ...entitySchemasBySnakeCaseName}
  },

  [coreActions.bootstrap]: {
    success: (draft, { modelData }, state) => {
      const { entitySchemasBySnakeCaseName, entitySchemasByName } = createEntitySchemas(modelData)
      const entitySelectors = createEntitySelectors(entitySchemasByName, modelData)

      draft.models = {...state.models, ...modelData}
      draft.entitySchemasBySnakeCaseName = {...state.entitySchemasBySnakeCaseName, ...entitySchemasBySnakeCaseName}
      draft.entitySelectors = {...state.entitySelectors, ...entitySelectors}
    },
  },

  [modelActions.RCR_MODEL_START_WATCH]: (draft, { name }) => {
    const modelUpperCaseName = changeCase.snake(name).toUpperCase()

    if (draft.modelWatch[modelUpperCaseName]) {
      draft.modelWatch[modelUpperCaseName].watchCount += 1
      // draft.modelWatch[modelUpperCaseName] = {...draft.modelWatch[modelUpperCaseName], watchCount: draft.modelWatch[modelUpperCaseName].watchCount + 1}
    } else {
      draft.modelWatch[modelUpperCaseName] = {watchCount: 1, version: 0}
    }
  },

  [modelActions.RCR_MODEL_STOP_WATCH]: (draft, { name }) => {
    const modelUpperCaseName = changeCase.snake(name).toUpperCase()

    if (draft.modelWatch[modelUpperCaseName] && draft.modelWatch[modelUpperCaseName].watchCount > 1 ) {
      draft.modelWatch[modelUpperCaseName].watchCount -= 1
    } else {
      draft.modelWatch[modelUpperCaseName] = {}
    }
  },

  '__default': (draft, payload, state, action) => {
    if (action && action.meta && action.meta.status == 'SUCCESS' && (action.type == 'RCR_CREATE_ENTITY' || action.type == 'RCR_UPLOAD' || action.type.indexOf('CREATE_') === 0)) {
      let modelName
      if (action.type == 'RCR_CREATE_ENTITY') {
        modelName = changeCase.snake(action.meta.rcrModelName)
      } else if (action.type == 'RCR_UPLOAD') {
        modelName = changeCase.snake(action.payload.modelName)
      } else {
        modelName = action.type.replace('CREATE_', '')
      }

      const modelUpperCaseName = modelName.toUpperCase()
      if (draft.modelWatch[modelUpperCaseName] && draft.modelWatch[modelUpperCaseName].version !== undefined) {
        draft.modelWatch[modelUpperCaseName].version += 1
      }

    }
  }


})
