import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// Rncui
import rncUiActions from 'runic-aura/systems/runic-aura/actions'

const useRncActivate = (kind, activated, deactivate) => {
  const dispatch = useDispatch()

  let action
  let deactivateAction
  if (kind === 'domain') action = rncUiActions.rncActivateDomain({ domainName: activated.name })
  else if (kind === 'unit') action = rncUiActions.rncActivateUnit({ unitName: activated.name })
  else if (kind === 'detail') {
    action = rncUiActions.rncActivateDetail(activated)
    deactivateAction = rncUiActions.rncDeactivateDetail()
  }

  React.useEffect(() => {
    dispatch(action)
    if (deactivateAction) return () => dispatch(deactivateAction)
  }, [activated])
}

export default useRncActivate