import React from 'react'

// Vendor
import { matchPath } from 'react-router'

// Rncui
import { LocationRouterContext, MatchRouterContext, ImmutableRouterContext } from '../RncRouterContext'

function isShallowEqual(x, y) {
  if (x === y) return true
  if (!x || !y) return true

  const keysOfX = Object.keys(x)
  const keysOfY = Object.keys(y)

  if (keysOfX.length !== keysOfY.length) return false
  for (let i = 0; i < keysOfX.length; i++) {
    const keyToCompare = keysOfX[i]
    if (x[keyToCompare] !== y[keyToCompare]) return false
  }

  return true
}

function isEmptyChildren(children) {
  return React.Children.count(children) === 0
}

function evalChildrenDev(children, props, path) {
  const value = children(props)

  warning(
    value !== undefined,
    "You returned `undefined` from the `children` function of " +
    `<Route${path ? ` path="${path}"` : ""}>, but you ` +
    "should have returned a React element or `null`"
  )

  return value || null
}

const RncRoute = (props) => {
  const { computedMatch, path } = props

  const location = React.useContext(LocationRouterContext)
  const matchFromContext = React.useContext(MatchRouterContext)
  const history = React.useContext(ImmutableRouterContext)

  const currentMatchRef = React.useRef(matchFromContext)

  const match = computedMatch
    ? computedMatch
    : path
      ? matchPath(location.pathname, props)
      : matchFromContext

  const isMatchNotEqual = (
    match.url !== currentMatchRef.current.url ||
    match.path !== currentMatchRef.current.path ||
    match.isExact !== currentMatchRef.current.isExact
  )

  if (isMatchNotEqual) currentMatchRef.current = match
  const currentMatch = currentMatchRef.current

  let { children, component, render } = props

  if (Array.isArray(children) && children.length === 0) {
    children = null
  }

  return (
    <MatchRouterContext.Provider value={currentMatch}>
      {currentMatch
        ? children
          ? typeof children === "function"
            ? __DEV__
              ? evalChildrenDev(children, { match: currentMatch, location, history }, path)
              : children({ match: currentMatch, location, history })
            : children
          : component
            ? React.createElement(component, { match: currentMatch, location, history })
            : render
              ? render({ match: currentMatch, location, history })
              : null
        : typeof children === "function"
          ? __DEV__
            ? evalChildrenDev(children, { match: currentMatch, location, history }, path)
            : children({ match: currentMatch, location, history })
          : null}
    </MatchRouterContext.Provider>
  )
}

export const RncStaticRoute = ({ match, children }) => (
  <MatchRouterContext.Provider value={match}>
    {children}
  </MatchRouterContext.Provider>
)

export default RncRoute

