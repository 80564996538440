export const getActiveRcUserId = (state) => state.core.activeRcUserId
export const getActiveRcTenantId = (state) => state.core.activeRcTenantId
export const getActiveRcUser = state => state.model.entitySelectors?.rcUser(state, getActiveRcUserId)
// export const getActiveRcUser = state => state.core.activeRcUser
// export const getActiveRcUser = state => console.log(state.model.entitySelectors == null)

// export const getActiveRcTenant = state => state.model.entitySelectors.rcTenant.createSelector(getActiveRcTenantId)(state)
export const getActiveRcTenant = state => state.core.activeRcTenant

// export const getRcTenantList = entitySelectors.rcTenant.listSelector
// export const getRcTenant = entitySelectors.rcTenant

export const getUserRcTenantList = state => state.model.entitySelectors?.rcTenant.createListSelector('withList')(state, state.core.rcTenantList)()