import React from 'react'

// Vendor
import { Link } from 'runic-aura/router'

// Volcano
import { Box } from 'volcano'
import Image from 'volcano/components/Image'
// import RncLogo from './RncLogo'
import RunicTopActionBar from './RunicTopActionBar'
// import RncTopMenuBar from './RncTopMenuBar'


export const TopBarSeparator = props => (
  <Box
    {...props}
    __css={{
      borderLeft: '1px solid',
      borderLeftColor: 'light2',
      height: '20px',
      width: '1px',
      margin: '8px 20px 0',
    }}
  />
)

export const TopBarWrapper = props => (
  <Box
    {...props}
    __css={{
      backgroundColor: 'light',
      height: '50px',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9,
      p: 3
    }}
  />
)

export const TopBarTextWrapper = props => (
  <Box
    {...props}
    __css={{
      margin: '8px 0px 0',
    }}
  />
)

const RunicTopBar = ({ isReady, children }) => (
  <TopBarWrapper>
    <Link to='/'><Image sx={{}} src={require(`runic-aura/static/img/logo-r.svg`)}/></Link>
    {isReady && (
      <>
        <RunicTopActionBar />
        {/* <RncTopMenuBar /> */}
      </>
    )}

    {children}
  </TopBarWrapper>
)

export default RunicTopBar