import React from 'react'

// Reverb
import { createBoxAs, space, layout, color, border, typography, t } from 'volcano'

// Volcano
import * as changeCase from 'volcano/util/text'


const TextElem = createBoxAs(
  'span',
  space,
  layout,
  border,
  color,
  typography,
)

const Text = ({textCase, asText = false, block, id, children, wrap, ...rest}) => {

  let content = id || children
  if (!asText) {
    const origText = id || children
    content = window.translations && window.translations.generic && window.translations.generic[origText]
    if (!content) {
      content = origText
      // __DEV__ && console.log(`MISSING TRANSLATION: ${origText}`)
    }
  }

  if (textCase === 'upperCase' || textCase === 'upper') content = changeCase.localeUpperCase(content, 'tr')
  else if (textCase === 'title') content = changeCase.title(content)
  else if (textCase === 'camel') content = changeCase.camelCase(content)
  else if (textCase === 'pascal') content = changeCase.pascalCase(content)
  else if (textCase === 'sentence') content = changeCase.sentenceCase(content)

  return (
    <TextElem
      as={block ? 'div' : undefined}
      sx={{
        wordBreak: wrap ? 'break-all' : ''
      }}
      {...rest}>
      {content}
    </TextElem>
  )
}

export default Text