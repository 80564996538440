import React from 'react'

// Vendor
import SVG from 'react-inlinesvg'

// Reverb
import { createBoxAs, space, layout, color, border, t, system, useTheme } from '../index'


const fill = system({
  fill: {
    property: 'fill',
    scale: 'colors',
  },
})

const Svg = createBoxAs(
  SVG,
  space,
  layout,
  border,
  fill,
)


const Icon = React.forwardRef((props, ref) => {
  const { name, icon, color = 'currentColor', ...rest } = props
  const theme = useTheme()
  const baseAssetUrl = theme.config.baseAssetUrl
  return (
    <Svg
      ref={ref}
      src={`${baseAssetUrl}/svg/${name || icon}.svg`}
      // style={{}}
      width={16}
      height={16}
      sx={{
        ...props.sx,
        verticalAlign: 'middle'
      }}
      fill={color}
      {...rest}
    >
    </Svg>
  )
})

export default Icon