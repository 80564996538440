import React from 'react'

// Reverb
import { Box, Flex } from 'volcano'


const Alert = ({kind = 'waring', children, ...rest}) => (
  <Box
    {...rest}
    sx={{
      bg: kind,
      p: 3,
      borderRadius: 1,
    }}
    >
    {children}
  </Box>
)

export default Alert