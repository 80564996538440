import { hot } from 'react-hot-loader/root'
import React from 'react'

// Riva
import RunicApp from 'runic-aura/RunicApp'

// Project
import storeCreator from './core/store'
import theme from './core/theme'
import globalCss from './theme/globalCss'

import rcAppSettings from 'elements/rc_app/settings'
import rnxPaymentSettings from 'elements/rnx_payment/settings'
import fulfilment from 'elements/rnx_fulfilment/fulfilment'

const apps = [
  rcAppSettings,
  rnxPaymentSettings,
  fulfilment
]


const App = () => (
  <RunicApp
    storeCreator={storeCreator}
    config={{
      isMultiTenant: true,
      runicPlatform: 'RUNIC_MAIN'
    }}
    theme={theme}
    globalCss={globalCss}
    apps={apps}/>
)

export default hot(App)
