import React from 'react'

// Vendor
import { matchPath } from 'react-router'

// Rncui
import { LocationRouterContext, MatchRouterContext } from '../RncRouterContext'


const RncSwitch = props => {
  const location = React.useContext(LocationRouterContext)
  const matchFromContext = React.useContext(MatchRouterContext)

  let element, match

  React.Children.forEach(props.children, child => {
    if (match == null && React.isValidElement(child)) {
      element = child

      const path = child.props.path || child.props.from

      match = path
        ? matchPath(location.pathname, { ...child.props, path })
        : matchFromContext
    }
  })

  return match
    ? React.cloneElement(element, { location, computedMatch: match })
    : null
}

export default RncSwitch