// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import actionActions from './actions'


const defaultState = {
  actionGroups: {},
  actionMaps: {},
  actionsInProgress: [],
  hasActionInProgress: false
}

export default createReducer(defaultState, {
  [actionActions.RCR_GET_ACTION_GROUP]: {
    success: (draft, { actionGroup }) => {
      if (!actionGroup) return
      draft.actionGroups[actionGroup.name] = {
        ...draft.actionGroups[actionGroup.name],
        ...actionGroup
      }
    },
  },

  [actionActions.RCR_GET_ACTION_GROUPS_FOR_MODEL]: {
    success: (draft, { actionGroups, fetchKey }) => {
      if (!actionGroups) return draft

      if (fetchKey) {
        actionGroups.forEach(actionGroup => {
          const data = draft.actionGroups[actionGroup.name] ? ({
            ...draft.actionGroups[actionGroup.name][fetchKey],
            ...actionGroup
          }) : ({
            ...actionGroup
          })

          draft.actionGroups[actionGroup.name] = {
            ...draft.actionGroups[actionGroup.name],
            [fetchKey]: data
          }
        })
      } else {
        actionGroups.forEach(actionGroup => {
          draft.actionGroups[actionGroup.name] = {
            ...draft.actionGroups[actionGroup.name],
            ...actionGroup
          }
        })
      }
    },
  },

  '__default': (draft, payload, state, action) => {
    if (action && action.meta && action.meta.status == 'START' && action.meta.kind !== 'CUSTOM') {
      draft.actionsInProgress.push(action)
      draft.hasActionInProgress = true
    } else if (action && action.meta && action.meta.kind !== 'CUSTOM' && (action.meta.status == 'SUCCESS' || action.meta.status == 'ERROR')) {
      const actionIndex = state.actionsInProgress.findIndex(act => act.type == action.type)
      if (actionIndex == -1) return

      draft.actionsInProgress.splice(actionIndex, 1)
      if (state.actionsInProgress.length == 1) draft.hasActionInProgress = false
    }
  }

})
