import changeCase from 'change-case'
export * from 'change-case'

export const lowerCaseFirst = str => str.charAt(0).toLowerCase() + str.substr(1)
export const camel = changeCase.camelCase
export const pascal = changeCase.pascalCase
export const title = changeCase.title
export const snake = changeCase.snakeCase


const SUPPORTED_LOCALE = {
  tr: {
    regexp: /[\u0069]/g,
    map: {
      i: "\u0130"
    }
  },
  az: {
    regexp: /[\u0069]/g,
    map: {
      i: "\u0130"
    }
  },
  lt: {
    regexp: /[\u0069\u006A\u012F]\u0307|\u0069\u0307[\u0300\u0301\u0303]/g,
    map: {
      i̇: "\u0049",
      j̇: "\u004A",
      į̇: "\u012E",
      i̇̀: "\u00CC",
      i̇́: "\u00CD",
      i̇̃: "\u0128"
    }
  }
};

export const localeUpperCase = (str, locale) => {
  const lang = SUPPORTED_LOCALE[locale.toLowerCase()]
  if (lang) return upperCase(str.replace(lang.regexp, m => lang.map[m]))
  return upperCase(str)
}

export const upperCase = (str) => {
  return str.toUpperCase()
}