import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import * as changeCase from 'volcano/util/text'

// Runic
import { getActionGroup, getActionGroupWithFetchKey } from 'runic/systems/action/selectors'
import actionGroupActions from 'runic/systems/action/actions'
import useRunicAction from 'runic/hooks/useRunicAction'
import { createFetchKey } from 'runic/hooks/useFetchKey'

// Rncui
import QuickForm from 'runic-aura/components/QuickForm'

// Reverb
import { Menu, MenuItem } from 'volcano/components/Menu'
import { ButtonIcon } from 'volcano/components/Button'
import { Box } from 'volcano'
import Alert from 'volcano/components/Alert'
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'

const ModelCreateAction = ({
  action,
  modelData,
  onClose,
  onAction,
  createData,
  labels,
}) => {
  const [state, setState] = React.useState('FORM')
  const buttonLabel = action.ui.button_label || 'save'
  const submitAction = useRunicAction(action, modelData)

  const onSuccess = React.useCallback((payload) => {
    setState('SUCCESS')
    onAction && onAction(payload)
  }, [])
  console.log('---', action)
  let formData = action.data || {}
  if (action.ui && action.ui.data) formData = {...formData, ...action.ui.data}
  if (action.getData) formData = { ...formData, ...action.getData(entity) }
  formData = {
    // rcrModelName: modelData.name,
    // rcrComponentName: modelData.element,
    // rcrActionName: changeCase.pascal(action.name),
    ...createData,
    ...formData
  }

  if (state == 'SUCCESS') return (
    <Box textAlign={'center'} p={2} width={300}>
      <Alert kind='success'> <Icon name='circle-empty-check' color='light' /><Text color='light'>Başarılı</Text> </Alert>
    </Box>
  )

  return (
    <Box p={3} width={300}>
      <Box mb={3}>
      { labels && labels.create || <Text id={`label.${modelData.name}`} textCase='title' />}
      </Box>
      {action.message && (
        <div>
          <Text id={action.message} textCase='title' />
        </div>
      )}
      <QuickForm
        buttonLabel={buttonLabel}
        submitAction={submitAction}
        fields={action.ui.fields}
        formData={formData}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Box>
  )
}


const ModelAction = ({
  action,
  modelData,
  text,
  onAction,
  createData,
  labels
}) => {
  const menuRef = React.useRef()
  if (action.kind != 'Create') return null

  let icon
  if (action.kind == 'Create') icon = 'plus'
  return (
    <Menu anchor={<span><ButtonIcon iconName={icon} variant='flat' />{text}</span>} ref={menuRef}>
      <ModelCreateAction action={action} modelData={modelData} onClose={menuRef.current && menuRef.current.close} onAction={onAction} createData={createData} labels={labels}/>
    </Menu>
  )
}

const ModelActionGroup = ({
  modelName,
  actionGroupKind,
  text,
  onAction,
  params,
  createData,
  labels,
}) => {
  const modelData = useSelector(state => state.model.models[modelName])
  const modelIdentifier = modelData && modelData.identifier
  const [actionGroups, actionGroupsSet] = React.useState()

  let fetchKey = params ? createFetchKey(...Object.values(params)) : null

  const [actionGroup, actions] = useSelector(state => {
    if (fetchKey) return getActionGroupWithFetchKey(state, modelIdentifier, actionGroupKind, null, fetchKey)
    else return getActionGroup(state, modelIdentifier, actionGroupKind, fetchKey)
  })
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actionGroupActions.rcrGetActionGroupsForModel({modelIdentifier, actionGroupKind, params, fetchKey}))
  }, [modelIdentifier, actionGroupKind])

  if (!actions) return null

  if (actions.length == 0) return null

  if (actions.length == 1) return <ModelAction action={actions[0]} modelData={modelData} text={text} onAction={onAction} createData={createData} labels={labels}/>

  return null
}

export default ModelActionGroup