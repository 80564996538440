import React from 'react'

// Riva
import { useFetchSourceData } from 'runic/hooks/source'

// Reverb
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import Button from 'volcano/components/Button'

import settingsActions from 'elements/rc_app/settings/actions'


const ApiSettingsItem = ({
  keys,
  baseKey,
}) => {
  console.log(keys,baseKey)
  const [rnxPaymentList] = useFetchSourceData('RnxPayment', 'PaymentMethod', 'list')
  if (!rnxPaymentList) return null
  return (
    <div>
      {rnxPaymentList.map((rnxPaymentMethod, rnxPaymentMethodDx) => {
        return (
          <div key={rnxPaymentMethodDx}>
            {rnxPaymentMethod.name} - {rnxPaymentMethod.code_name}
            {Object.keys(keys).map((key, keyDx) => {
        const keyData = keys[key]
        return (
          <ApiKeyForm key={keyDx} keyData={keyData} baseKey={baseKey} rnxPaymentMethod={rnxPaymentMethod}/>
        )
      })}
          </div>
        )
      })}
    </div>
  )
}

const ApiKeyForm = ({keyData, baseKey, rnxPaymentMethod}) => {
  const [displayForm, displayFormSet] = React.useState(false)
  const [result, resultSet] = React.useState({})

  const [formProps, isSubmitting] = useRunicForm(settingsActions.updateSetting, {
    action: (data, {setSubmitting}) => {
      return [
        {
          settingsKey: baseKey,
          data: {
            key: keyData.key,
            rnxPaymentMethodId: rnxPaymentMethod.id,
            ...data,          
          }
        },
        {
          success: (response) => {
            resultSet({ state: 'SUCCESS' })
          },
          error: (response) => {
            resultSet({ error: response, state: 'ERROR' })
          }
        }
      ]
    }
  })

  const displayFormToggle = () => displayFormSet((e) => !e)

  let content
  if (displayForm) content = (
    <>
      <Form {...formProps}>
        <TextField name={keyData.key} label={keyData.name} schema={yup.string().required()} required focus />
        <TextField name='password' type='password' label='Şifreniz' schema={yup.string().required()} required />
        <Button label='save' fullWidth submit working={isSubmitting} />
      </Form>
    </>
  )

  return (
    <div>
      <a onClick={displayFormToggle}>{keyData.name}</a>
      {content}
      {result.state == 'SUCCESS' && 'Başarılı'}
      {result.state == 'ERROR' && 'Hata'}
    </div>
  )
}

export default ApiSettingsItem