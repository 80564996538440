// Riva - Function
import { createActions } from 'runic/core/redux'

const BASE_COMPONENT = 'RcEntity'

const ACTIONS = {
  RCR_ACTION: {
    RCR_QUICK_UPDATE: {},
    RCR_SET_R_VARIABLE: {},
    CREATE_RC_ENTITY_PROPERTY_VALUE_FOR_RC_ENTITY: {component: 'RcEntityProperty'},
    RCR_QUICK_UPDATE_MULTIPLE: {},
    RCR_QUICK_ACTION: {} // FIXME: move to model actions? or move model actions to here?
  },
}

export default createActions(ACTIONS, 'entity', BASE_COMPONENT)


export const getEntityList = (component, archive, search, actionName, payload, callbacks) => ({
  type: actionName,
  meta: {
    status: 'START',
    kind: 'RCR_ARCHIVE',
    component: component,
    archive: archive,
    search: search
  },
  payload,
  callbacks,
})

export const doSourceQuery = (component, archive, search, actionName, payload, callbacks, sourceUrlPath) => ({
  type: actionName,
  meta: {
    status: 'START',
    kind: 'RCR_SOURCE',
    component: component,
    archive: archive,
    search: search,
    sourceUrlPath: sourceUrlPath
  },
  payload,
  callbacks,
})

export const getEntityFromSource = (component, entity, payload, callbacks, search, archiveName) => doSourceQuery(component, archiveName || entity, search || 'detail', `GET_${entity.toUpperCase()}`, payload, callbacks)

export const getEntity = (component, entity, payload, callbacks, search, archiveName) => ({
  type: `GET_${entity.toUpperCase()}`,
  meta: {
    status: 'START',
    kind: 'RCR_ARCHIVE',
    component: component,
    archive: archiveName || entity,
    search: search || 'detail'
  },
  payload,
  callbacks,
})

// FIXME: find a better place
export const getEntityInformation = (component, entity, payload, callbacks, search) => ({
  type: `GET_ENTITY_INFORMATION`,
  meta: {
    status: 'START',
    kind: 'RCR_SOURCE',
    component: component,
    archive: entity,
    search: search || 'detail'
  },
  payload,
  callbacks,
})

// FIXME: find a better place
export const getSystemComponentOptions = (payload, callbacks, search) => ({
  type: `RCR_GET_SYSTEM_COMPONENT_OPTIONS`,
  meta: {
    status: 'START',
    kind: 'RCR_ARCHIVE',
    component: 'RcSystem',
    archive: 'RSystemMeta',
    search: 'rcr_get_component_options'
  },
  payload,
  callbacks,
})