// Vendor
import { css } from '@emotion/core'

// Reverb

const mainFont = 'IBMPlexSans'
const mainFontExtension = 'otf'

const headerFont = 'TitilliumWeb'
const headerFontExtension = 'ttf'

export default theme => css`
@font-face
{
  font-family: 'Reverb Main';
  font-weight: 300;
  src: url(/_asset/static/font/${mainFont}-Light.${mainFontExtension});
}

@font-face
{
  font-family: 'Reverb Main';
  font-weight: 400;
  src: url(/_asset/static/font/${mainFont}-Regular.${mainFontExtension});
}

@font-face
{
  font-family: 'Reverb Main';
  font-weight: 500;
  src: url(/_asset/static/font/${mainFont}-SemiBold.${mainFontExtension});
}

@font-face
{
  font-family: 'Reverb Main';
  font-weight: 700;
  src: url(/_asset/static/font/${mainFont}-Bold.${mainFontExtension});
}

@font-face
{
  font-family: 'Reverb Header';
  font-weight: 300;
  src: url(/_asset/static/font/${headerFont}-Light.${headerFontExtension});
}

@font-face
{
  font-family: 'Reverb Header';
  font-weight: 400;
  src: url(/_asset/static/font/${headerFont}-Regular.${headerFontExtension});
}

@font-face
{
  font-family: 'Reverb Header';
  font-weight: 500;
  src: url(/_asset/static/font/${headerFont}-SemiBold.${headerFontExtension});
}

@font-face
{ 
  font-family: 'Reverb Header';
  font-weight: 700;
  src: url(/_asset/static/font/${headerFont}-Bold.${headerFontExtension});
}

a {
  text-decoration: none;
  cursor: pointer;
  color: ${theme.colors.dark};
}

body {
  font-family: ${theme.fontFamily};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.base};
  color: ${theme.colors.dark};
  background: ${theme.colors.light};
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}
`