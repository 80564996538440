//

export const getActionGroup = (state, actionGroupName, actionGroupK, entityIdOrEntityIds) => {
  const actionGroup = state.action.actionGroups[actionGroupName]
  if (!actionGroup) return []

  let actions

  if (actionGroupK == 'Entity') {
    const entityId = entityIdOrEntityIds
    const entityActionList = actionGroup.actionsById && actionGroup.actionsById[entityId]
    if (!entityActionList) return []

    actions = entityActionList
  } else {
    actions = actionGroup.actions
  }

  return [
    actionGroup,
    actions
  ]
}

export const getActionGroupWithFetchKey = (state, actionGroupName, actionGroupK, entityIdOrEntityIds, fetchKey) => {
  const actionGroup = state.action.actionGroups[actionGroupName] && state.action.actionGroups[actionGroupName][fetchKey]
  if (!actionGroup) return []

  let actions

  if (actionGroupK == 'Entity') {
    const entityId = entityIdOrEntityIds
    const entityActionList = actionGroup.actionsById && actionGroup.actionsById[entityId]
    if (!entityActionList) return []

    actions = entityActionList
  } else {
    actions = actionGroup.actions
  }

  return [
    actionGroup,
    actions
  ]
}

export const getActionMap = (state, actionMapKey) => state.action.actionMaps[actionMapKey]
export const getActionMaps = (state) => state.action.actionMaps