import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import coreActions from 'runic/systems/core/actions'

import { Box } from 'volcano'

// RunicAura
import runicAuraActions from 'runic-aura/systems/runic-aura/actions'

import { Route, Switch, Redirect } from 'runic-aura/router'
import AuthenticationBase from 'runic-aura/apps/authentication/pages/AuthenticationBase'

import RunicTopErrorBoundary from '../components/RunicTopErrorBoundary'
import RunicTopBar from '../components/RunicTopBar'
import RunicHome from '../pages/RunicHome'


const RunicIndex = ({
  uiConfig,
  runicConfig,
  homeUrl = 'home',
  homeComponent
}) => {

  const dispatch = useDispatch()
  const appStatus = useSelector(state => state.core.appStatus)

  React.useEffect(() => {
    dispatch(runicAuraActions.setupUi({ config: uiConfig }))
    // dispatch(runicAuraActions.rncRegisterComponents({ components: director.getComponents() }))
    dispatch(coreActions.runicBootstrap({ config: runicConfig }))
    dispatch(coreActions.bootstrap({}))
  }, [])

  const isReady = appStatus == 'READY'
  const Home = homeComponent || RunicHome

  return (
    <RunicTopErrorBoundary>
      {/* <RenoaVersionNotification /> */}
      <RunicTopBar isReady={isReady} />
        {isReady && (
          <Switch>
            <Route path="/auth">
              <AuthenticationBase />
            </Route>
            <Route path="/:route">
              <Home />
            </Route>
            <Redirect from='/' to={homeUrl} />
          </Switch>
        )}
    </RunicTopErrorBoundary>
  )
}

export default RunicIndex
