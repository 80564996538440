import React from 'react'

// Vendor
import * as changeCase from 'change-case'

import { Link } from 'runic-aura/router'

// Volcano
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import { styled, t, tt, tc } from 'volcano'



export const SidebarWrapper = styled.div`
  background: ${tc('light4')};
  border-radius: 0 4px 4px 0;
  width: ${p => p.extended ? '220px' : '65px'};
  min-height: 20px;

  position: sticky;
  margin-top: 90px; 

  padding-bottom: 5px;
  margin-right: -30px;
  overflow: hidden;
`

export const SidebarSeparator = styled.hr`
  margin: 10px auto;
  border-top: 1px solid #E0E0E9;
  width: ${p => p.extended ? '80%' : '40%'};
`


export const SidebarGroupWrapper = styled.div`
  margin-bottom: 20px;
`

export const SidebarGroupHeader = styled.div`
  font-weight: ${t('fontWeights.bold')};
  font-size:  ${t('fontSizes.s')};
  color: ${tc('primary3')};
  margin-left: 20px;
`

export const SidebarItemLink = styled.div`
  text-align: ${p => p.extended ? 'left' : 'center'};
  ${p => p.extended && 'padding-left: 13px;'}
  display: block;
  width: 100%;
  margin: 10px 0;

  color: ${tc('dark8')};
  ${p => p.distinct && `color: ${tc('primary')(p)};`}

  ${p => p.active && `border-left: 3px solid ${tc('primary')(p)}; border-radius: 0px 4px 4px 0; color: ${tc('dark')(p)};`}
  box-sizing: border-box;

  transition: all ease 0.3s;
`

const SidebarItemText = styled.span`
  ${p => p.extended ? 'font-weight: 500' : 'font-weight: bold'};
  font-size: ${p => p.extended ? t('fontSizes.s') : t('fontSizes.xs')};

  text-overflow: ellipsis;
  overflow: hidden;
  ${p => !p.extended && 'display: block;margin-top: 5px;'}
  ${p => p.extended && 'display: inline-block;margin-top: 5px;'}

  width: 80%;
`

export const SidebarMenuTextWrapper = styled.div`
  margin: 10px 20px;
  display: block;
  width: calc(100% - 40px);
`

export const SidebarItem = ({ icon, to, name, extended, ...rest }) => {
  const content = (
    <SidebarItemLink {...rest} extended={extended}>
      {extended ? (
        <>
          <Icon name={icon} mr={2} />
          <SidebarItemText extended={extended}><Text textCase='upper' id={`label.${changeCase.snakeCase(name)}`} /></SidebarItemText>
        </>
      ) : (
          <>
            <div><Icon name={icon} mr={2} /></div>
            <SidebarItemText><Text textCase='upper' id={`label.${changeCase.snakeCase(name)}`} /></SidebarItemText>
          </>
        )}
    </SidebarItemLink>
  )

  return to ? (
    <Link to={to}>
      {content}
    </Link>
  ) : (
      <a>
        {content}
      </a>
    )
}

export const RncMenuItem = props => {
  const { unit, activeUnit, uiConfig, menuItem, basePath } = props
  if (!unit) {
    console.log('UNIT_NOT_FOUND', menuItem.unitName)
    return null
  }

  const active = activeUnit && (unit.name == activeUnit.unitName)

  const unitKind = unit.kind || (unit.modelName ? 'r1' : 'r2')
  const path = `${basePath}${unitKind}/${menuItem.path}/`
  return (
    <SidebarItem icon={unit.icon} name={unit.displayName} active={active} to={path} extended={uiConfig.extended} />
  )
}