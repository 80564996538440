import RncStandardDomain from './pages/RncStandardDomain'

export default {
  name: 'runicAura.domain.standardDomain',
  directory: {
    runicAura: {
      domainHandlers: {
        StandardDomain: RncStandardDomain
      }
    }
  }
}