import React, { useEffect } from 'react'

// Vendor
import { createLocation } from "history"
import { generatePath } from 'react-router'

// Rncui
import { LocationRouterContext, MatchRouterContext, ImmutableRouterContext } from '../RncRouterContext'

class Lifecycle extends React.Component {
  componentDidMount() {
    if (this.props.onMount) this.props.onMount.call(this, this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.onUpdate) this.props.onUpdate.call(this, this, prevProps)
  }

  componentWillUnmount() {
    if (this.props.onUnmount) this.props.onUnmount.call(this, this)
  }

  render() {
    return null
  }
}

const RncRedirect = ({
  computedMatch,
  to,
  push = false
}) => {
  const { history, staticContext } = React.useContext(ImmutableRouterContext)
  const { locationsAreEqual } = history

  const method = push ? history.push : history.replace
  const location = createLocation(
    computedMatch
      ? typeof to === "string"
        ? generatePath(to, computedMatch.params)
        : {
          ...to,
          pathname: generatePath(to.pathname, computedMatch.params)
        }
      : to
  )

  if (staticContext) {
    method(location)
    return null
  }

  return (
    <Lifecycle
      onMount={() => {
        method(location)
      }}
      onUpdate={(self, prevProps) => {
        const prevLocation = createLocation(prevProps.to)
        if (
          !locationsAreEqual(prevLocation, {
            ...location,
            key: prevLocation.key
          })
        ) {
          method(location)
        }
      }}
      to={to}
    />
  )
}

export default RncRedirect