import React from 'react'

// Vendor
import styled from '@emotion/styled'

// Rnc
import RunicUserMark from './RunicUserMark'
import { TopBarSeparator } from './RunicTopBar'


const TopActionBarWrapper = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  margin: 8px 20px;

  display: flex;
  z-index: 9;
`

const TopImg = styled.img`
  margin: 6px 10px;
`

const RunicTopActionBar = props => (
  <TopActionBarWrapper>
    <TopBarSeparator />
    <RunicUserMark />
  </TopActionBarWrapper>
)

export default RunicTopActionBar