import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'

// Reverb
import WindowedTable, { CellWrapper, HeaderColumnWrapper, HeaderColumnTopLabel, HeaderColumnLabelText, HeaderColumnLabel, RowWrapper } from 'volcano/components/WindowedTable'
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import TextField from 'volcano/components/TextField'

// Rncui
import { getColumnPropsFromListView, getRenderDataForField, getLabelForField } from '../util'
import { RelatedEntityCell, EntityCell } from './ModelHelpers'
import EntityActionGroupMenu from './EntityActionGroupMenu'

// Row

const CustomCell = props => {
  const { entity, column } = props

  return (
    <CellWrapper colWidth={column.colWidth} flexGrow={column.flexGrow}>
      <EntityActionGroupMenu entity={entity} modelName={entity._rcr_model_name}/>  
    </CellWrapper>
  )
}

const Row = ({ style, index, data, wrapperProps }) => {

  if (!data.rows || !data.rows[index]) return null

  const { id, columns, modelName, identifier } = data.rows[index]
  const modelDataMap = data.modelDataMap
  const entity = useSelector(state => state.entity[modelName].entity[id])

  const content = columns.map((column, dx) => {
    let content
    if (column.name) content = <CustomCell key={dx} entity={entity} modelName={modelName} column={column} />
    else if (column.columnFieldData.is_relationship) content = <RelatedEntityCell key={dx} entity={entity} columnAttrPath={column.fieldAttrPath} columnFieldData={column.columnFieldData} column={column} identifier={identifier} modelDataMap={modelDataMap} />
    else content = <EntityCell key={dx} entity={entity} columnAttrPath={column.fieldAttrPath} columnFieldData={column.columnFieldData} column={column} identifier={identifier} />
    return content
  })

  return (
    <RowWrapper style={{ ...style }} {...wrapperProps}>
      {content}
    </RowWrapper>
  )
}

// Header

const HeaderColumn = props => {
  if (props.column && props.column.modelName) return <ModelHeaderColumn {...props} />
  else return <CustomHeaderColumn {...props} />
}

const HeaderSearch = props => {
  const { searchPath, modelData, updateLocationFilters, locationFilters, label: labelFromProps } = props
  const [value, setValue] = React.useState(locationFilters[searchPath] || '')

  const updateLocationFiltersDebounced = React.useCallback(debounce(updateLocationFilters, 500), [])

  const label = <Text textCase='title' id={`field.${labelFromProps}`} />

  const onChange = React.useCallback((e) => {
    const val = e.target.value
    setValue(val)
    updateLocationFiltersDebounced({ [searchPath]: val })
  }, [])

  return (<TextField label={label} value={value} onChange={onChange} focus/>)
}

const ModelHeaderColumn = props => {
  const { filterInstance, column, toggleSearch, modelDataMap } = props
  const { handleSort, updateLocationFilters, locationFilters } = filterInstance
  const { sortBy, sortDirection } = locationFilters

  const { columnFieldData } = column
  const modelData = modelDataMap[changeCase.pascal(column.modelName)]

  const [isSearchOpen, setSearchOpen] = React.useState()

  const [targetFieldData, columnPath, searchPath, modelList] = React.useMemo(() => getRenderDataForField(modelDataMap, columnFieldData, column.modelName, column.fieldAttrPath), [modelDataMap, columnFieldData, column.modelName, column.fieldAttrPath])
  const [label, topLabel, preLabel] = React.useMemo(() => getLabelForField(targetFieldData, columnPath, modelList, column.labelRenderer), [modelDataMap, targetFieldData, columnPath, column.labelRenderer])

  const toggleColumnSearch = React.useCallback(() => {
    const isSearchOpenNew = !isSearchOpen

    toggleSearch(isSearchOpenNew)
    setSearchOpen(isSearchOpenNew)
    if (!isSearchOpenNew) updateLocationFilters({ [searchPath]: undefined })
  }, [isSearchOpen, toggleSearch, searchPath])

  const columnSortBy = columnPath
  const columnSortDirection = sortBy == columnSortBy ? sortDirection : null
  let nextSortDirection = 'ASC'
  if (columnSortDirection == 'ASC') nextSortDirection = 'DESC'
  else if (columnSortDirection == 'DESC') nextSortDirection = null

  let hasSearch = true

  if (targetFieldData.model_attr_kind == 'DateTime') hasSearch = false
  else if (targetFieldData.model_attr_kind == 'Boolean') hasSearch = false
  else if (targetFieldData.options && targetFieldData.options.translate) hasSearch = false
  return (
    <HeaderColumnWrapper>
      <HeaderColumnTopLabel>{topLabel}</HeaderColumnTopLabel>
      <HeaderColumnLabel>
        <HeaderColumnLabelText style={{ cursor: 'ns-resize' }} onClick={() => handleSort({ sortBy: columnSortBy, sortDirection: nextSortDirection })}>
          {columnSortDirection && <Icon name={`arrow-${columnSortDirection == 'ASC' ? 'up' : 'down'}`} style={{ fontSize: 10 }} />} {preLabel} {label}
        </HeaderColumnLabelText>
        {hasSearch && <a onClick={toggleColumnSearch}><Icon name='search' style={{ fontSize: 10 }} /></a>}
      </HeaderColumnLabel>
      {isSearchOpen && <HeaderSearch searchPath={searchPath} modelData={modelData} updateLocationFilters={updateLocationFilters} locationFilters={locationFilters} label={targetFieldData.name} />}
    </HeaderColumnWrapper>
  )
}

const CustomHeaderColumn = props => {
  const { column } = props

  let label, topLabel
  if (column.labelRenderer) label = column.labelRenderer(props)
  else label = <Text textCase='title' id={`field.${column.name}`} />

  return (
    <HeaderColumnWrapper>
      <HeaderColumnTopLabel>{topLabel}</HeaderColumnTopLabel>
      <HeaderColumnLabel>{label}</HeaderColumnLabel>
    </HeaderColumnWrapper>
  )
}

const defaultOptions = {}

const ModelListViewTable = ({
  lvInstance,
  filterInstance,
  options = defaultOptions
}) => {
  const { listView, modelData } = lvInstance
  const modelDataMap = useSelector(state => state.model.models)
  const activeModelData = useSelector(state => state.runicAura.activeModelData)

  const columns = React.useMemo(() => getColumnPropsFromListView(
    listView, modelData, options, activeModelData
  ), [listView, modelData, options])

  const data = React.useMemo(() => {
    if (!listView.list) return {
      rows: [],
      modelDataMap: {}
    }
    const rows = listView.list.items.map(
      id => ({ id, identifier: listView.view.identifier, columns, modelName: changeCase.camel(listView.modelName) })
    )

    return {
      rows,
      modelDataMap
    }
  }, [listView.items, columns])

  // console.log(columns, data)

  if (!listView) return null

  const loadMoreItems = React.useCallback((startIndex, stopIndex) => {
    lvInstance.refresh({opts: {startIndex}})
  }, [])

  // window.useWhyDidYouRender({lvInstance, filterInstance, options})
  return (
    <WindowedTable
      key={modelData.identifier}
      padding={'0 15px'}
      data={data}
      dx={filterInstance.locationFilters.dx}
      onRender={({visibleStartIndex}) => {
        if (!filterInstance.locationFilters.dx) {
          if (visibleStartIndex > 10) filterInstance.updateLocationFilters({dx: visibleStartIndex}, {mode: 'replace', keepDx: true})
        } else {
          filterInstance.updateLocationFilters({dx: visibleStartIndex}, {mode: 'replace', keepDx: true})
        }
      }}
      columns={columns}
      count={listView.list ? listView.list.count : 0}
      isItemLoaded={index => listView.list.items[index] !== undefined}
      loadMoreItems={lvInstance.status.isLoading ? () => {} : loadMoreItems}
      Row={Row}
      // HeaderColumn={props => <HeaderColumn {...props} handleSort={handleSort} sortBy={sortBy} sortDirection={sortDirection} modelDataMap={modelDataMap} updateLocationFilters={updateLocationFilters}/>}
      HeaderColumn={HeaderColumn}
      headerColumnProps={{
        modelDataMap,
        filterInstance
      }}
      // forPrint={forPrint}
      // overscanCount={30}
    />
  )
}

export default ModelListViewTable
