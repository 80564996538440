import React from 'react'

// Rncui
import { Link } from 'runic-aura/router'

// Reverb
import { Box } from 'volcano'

const RunicLink = props =>
  <Box
    as={Link}
    {...props}
    __css={{
      color: 'primary4',
      textDecoration: 'underline'
    }}
    variants={{
      // flat: {
      //   color: 'primary',
      //   background: 'none',
      //   borderStyle: 'solid',
      //   borderWidth: 2,
      //   borderColor: 'transparent',
      //   transition: 'all 0.2s ease',
      //   '&:hover': {
      //     borderColor: 'primary',
      //     color: 'light',
      //     bg: 'primary',
      //   },
      //   '&:focus': {
      //     borderColor: 'primary3',
      //     outline: 'none',
      //   }
      // },
    }}
  />

export default RunicLink