import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// riva
import { getActiveRcUser } from 'runic/systems/core/selectors'
import authenticationActions from 'runic/systems/authentication/actions'


const AuthenticationLogout = props => {
  const activeUser = useSelector(getActiveRcUser)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(authenticationActions.rcrLogout({rcTenantId: activeUser && activeUser.rc_tenant_id}))
  }, [])

  return (null)
}

export default AuthenticationLogout
