import React from 'react'

// Rncui
import { useHistory } from 'runic-aura/router'


const useRncIndexRedirect = (match, indexUrl, redirectUrl) => {
  const history = useHistory()

  React.useEffect(() => {
    const urlFragmentToCheck = match.url.slice(match.url.length - indexUrl.length - 2, match.url.length)
    if (urlFragmentToCheck == `/${indexUrl}/`) history.replace(`${redirectUrl}/`)
  }, [match])
}

export default useRncIndexRedirect