import * as changeCase from 'volcano/util/text'

export const getModelData = (state, modelName) => {
  const modelData = state.model.models[modelName]
  return ({
    ...modelData,
    actionMap: modelData && state.action.actionMaps[modelData.identifier]
  })
}
export const getVersion = (state, modelName) => (state.model.modelWatch[modelName] && state.model.modelWatch[modelName].version) || 0
export const getEntityMetadata = (state, entityName) => state.orm.entityMetadata[changeCase.lowerCaseFirst(entityName)]