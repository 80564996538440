import Volcano from './Volcano'

import styled from '@emotion/styled'
import css, { get } from '@styled-system/css'
import { useTheme } from 'emotion-theming'

export { styled }
export { css, get }
export { useTheme }

import { system, variant } from 'styled-system'
export { system, variant }

import shouldForwardProp from '@styled-system/should-forward-prop'

export const t = (path, fallback = null) => props => get(props.theme, path, fallback)
export const tt = (path, fallbackPath, fallback = null) => props => get(props.theme, path, get(props.theme, fallbackPath, fallback))
export const tc = (path, fallback = null) => props => get(props.theme.colors, path, fallback)

export const sx = props => css(props.sx)(props.theme)
export const base = props => css(props.__css)(props.theme)

const rVariant = ({ theme, variant, __themeKey = 'variants' }) =>
  css(get(theme, __themeKey + '.' + variant, get(theme, variant)))

// export const variant = ({
//   theme,
//   variant,
//   tx = 'variants',
// }) =>
//   css(
//     get(theme, tx + '.' + variant,
//       get(theme, variant)
//     )
//   )(theme)

export const createBox = (...composed) => styled('div', {
  shouldForwardProp
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
},
  base,
  rVariant,
  sx,
  props => props.css,
  compose(
    ...composed
  ),
)

export const Box = styled('div', {
  shouldForwardProp
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
},
  base,
  // variant,
  sx,
  props => props.css,
  props => props.variants && variant({variants: props.variants}),
  compose(
    space,
    layout,
    typography,
    color,
    flexbox,
  ),
)

export const Flex = styled(Box)({
  display: 'flex'
})

export const createBoxAs = (as, ...composed) => styled(as, {
  shouldForwardProp
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
},
  base,
  rVariant,
  sx,
  props => props.css,
  compose(
    ...composed
  ),
)

export const Spacer = styled('div', {
  shouldForwardProp
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
},
  compose(
    space,
  ),
)

import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  border,
} from 'styled-system'

export {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  border,
}

export default Volcano