import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// Rncui
import { Switch, Route, useMatch } from 'runic-aura/router'
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

// Runic
import useDirector from 'runic/hooks/useDirector'


const RncModelUnit = ({
  domain,
  item,
  profileName = 'Default'
}) => {
  const match = useMatch()
  const unit = useSelector(state => state.runicAura.units[item.unitName])
  useRncActivate('unit', unit)
  const director = useDirector()

  const profile = unit.profiles[profileName]

  const HomeComponent = profile.pages?.home && director.components[profile.pages.home.name]
  const DetailComponent = profile.pages?.detail && director.components[profile.pages.detail.name]

  if (!HomeComponent || !DetailComponent) return null

  return (
    <Switch>
      <Route path={`${match.path}/r_detail/:unitId/`}>
        <DetailComponent unit={unit} profile={profile} backUrl={`${match.url}/`} />
      </Route>
      <Route path={`${match.path}`}>
        <HomeComponent unit={unit} profile={profile} />
      </Route>
    </Switch>
  )
}

export default RncModelUnit