import warning from 'warning'
export warning from 'warning'


export const addListener = (target, eventType, callback) => {
  if (target.addEventListener) {
    target.addEventListener(eventType, callback, false)
    return {
      remove: function() {
        target.removeEventListener(eventType, callback, false)
      }
    }
  } else if (target.attachEvent) {
    target.attachEvent('on' + eventType, callback)
    return {
      remove: function() {
        target.detachEvent('on' + eventType, callback)
      }
    }
  }
}

export const getInitials = (text, length) => {
  const _length = length || 2

  const splitText = text.split(' ')
  return splitText.length == 1 ? (
    text.substring(0, _length).toUpperCase()
  ) : (
    splitText.slice(0, length).map(word => (word[0].toUpperCase())).join('')
  )
}

export const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

// From https://github.com/callemall/material-ui/blob/v1-alpha/src/utils/helpers.js
export function createChainedFunction(...funcs) {
  return funcs.filter(func => func != null).reduce((acc, func) => {
    warning(
      typeof func === 'function',
      'Invalid Argument Type, must only provide functions, undefined, or null.',
    )

    if (acc === null) return func

    return function chainedFunction(...args) {
      acc.apply(this, args)
      func.apply(this, args)
    }
  }, null)
}

export const toLower = (value) => (value === null || value === undefined ? '' : value).toString().toLowerCase();


export const createInputElemeent = (accept, callback) => {
    const inputElement = document.createElement("input");

    inputElement.type = "file";
    inputElement.accept = accept;
    inputElement.multiple = true

    inputElement.addEventListener("change", callback)
    return inputElement
}