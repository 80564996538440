import React from 'react'

// Vendor
import { useField, useFormState, useForm } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import * as changeCase from 'volcano/util/text'

// Reverb
import { styled, t, tt, tc, Spacer, Box } from 'volcano'

import useTypeahead from 'runic-aura/hooks/useTypeahead'
import BareTextField from 'volcano/components/TextField'

import Popover from 'volcano/components/Popover'
import Text from 'volcano/components/Text'
import Icon from 'volcano/components/Icon'

import ModelActionGroup from './ModelActionGroup'


const FieldError = styled.div`
  font-size:  ${tt('input.fontSizeError', 'fontSizes.s')};
  color:  ${tt('input.colorError', 'colors.accent')};

  margin-top:  ${t('space.1')}px;
  margin-left: 13px;
  
`

const Error = ({ error }) => {
  if (!error) return null

  let message
  if (error.type === 'min') message = `En az ${error.message.min} karakter olmalı.`
  else if (error.type === 'email') message = `Geçerli bir eposta adresi girmelisin.`
  else {
    console.log('Unknown error type', error)
    message = "Geçersiz."
  }

  return (
    <FieldError>
      {message}
    </FieldError>
  )
}

const OptionWrapper = styled.div`
  padding: ${t('space.2')}px;

  ${p => p.selected && `background-color: ${tc('primary9')(p)}`};

  &:hover {
    background-color: ${tc('primary9')};
  }
`

const Option = props => (
  <OptionWrapper {...props}>{props.children}</OptionWrapper>
)

const Selected = styled.div`
  margin-top: 10px;
`

const LabelText = styled.div`
  font-size: ${t('fontSizes.s')};
  font-weight: ${t('fontWeights.bold')};

  margin-bottom: 5px;
`


const CreateLink = styled.a`
  text-align: right;
  display: block;
  font-size: ${t('fontSizes.m')};
  margin-top: 5px;
  color: ${tc('primary')};
`

const ChangeLink = styled.a`
  float: right;
`

const ModelTypeahead = React.forwardRef((props, ref) => {
  const { schema, modelName, name, create, createData, queryOnly, onSelect, ignored, afterItems, labels, ...rest } = props
  const { input, meta } = useField(name)
  const [options, selected, popoverProps, inputProps, getOptionProps, inputRef, { onCreate, reset }] = useTypeahead(modelName, option => {
    input.onChange(option ? option.value : null)
    onSelect && onSelect(option)
  }, {
    filters: props.filters,
    // ignored: ignoredValues,
    initialValue: input.value
  })

  const modelSnakeCaseName = changeCase.snake(modelName)

  return (
    <Spacer mb={3} sx={{ position: 'relative' }}>
      {((!selected) || (selected && popoverProps.isOpen)) && (
        <>
          <BareTextField {...inputProps} {...rest} afterIcon='chevron-down' afterItems={[
            <ModelActionGroup
              modelName={modelName}
              actionGroupKind='Create'
              onAction={(payload) => {
                onCreate(payload.rcEntity.result[modelSnakeCaseName])
              }}
              labels={labels}
              params={create}
              createData={createData} />,
              ...afterItems
          ]} />
          <Error error={meta.error} />
          <Popover {...popoverProps}>
            {options && options.map((opt, optDx) => (
              <Option key={opt.dx} {...getOptionProps(opt, selected)}>
                {opt.label}
              </Option>
            ))}
          </Popover>
          {/* {!queryOnly && (
        <BaseBox sx={{
          position: 'absolute',
          right: 0,
        }}>
        <ModelActionGroup
          modelName={modelName}
          actionGroupKind='Create'
          text={<Text fontSize='s'>Yeni {props.label} oluştur</Text>}
          onAction={(payload) => {
            onCreate(payload.rcEntity.result[modelSnakeCaseName])
          }}
          params={create}
          createData={createData}
          labels={{create: props.label}}/>
        </BaseBox>
      )}   */}
        </>
      )}

      {selected && !popoverProps.isOpen && (
        <Selected>
          <LabelText>{props.label}</LabelText>
          {selected.label} <ChangeLink onClick={reset}>Değiştir</ChangeLink>
        </Selected>
      )}
    </Spacer>
  )
})


const ModelTypeaheadInput = React.forwardRef((props, ref) => {
  const { schema, modelName, create, createData, queryOnly, onSelect, ignored, onChange, afterItems, labels, ...rest } = props
  const [options, selected, popoverProps, inputProps, getOptionProps, inputRef, { onCreate, reset }] = useTypeahead(modelName, option => {
    onChange && onChange(option ? option.value : null, option)
    onSelect && onSelect(option)
  }, {
    filters: props.filters,
    resetInputOnSelect: true,
    ignored,
  })

  const modelSnakeCaseName = changeCase.snake(modelName)

  return (
    <Spacer mb={3} sx={{ position: 'relative' }}>
      <BareTextField {...inputProps} {...rest} afterIcon='chevron-down' afterItems={[
        <ModelActionGroup
          modelName={modelName}
          actionGroupKind='Create'
          onAction={(payload) => {
            onCreate(payload.rcEntity.result[modelSnakeCaseName])
          }}
          params={create}
          labels={labels}
          createData={createData} />,
          ...afterItems
      ]} />
      <Popover {...popoverProps}>
        {options && options.map((opt, optDx) => (
          <Option key={opt.dx} {...getOptionProps(opt, selected)}>
            {opt.label}
          </Option>
        ))}
      </Popover>
    </Spacer>
  )
})

const emptyArray = []

export const RncMultiModelTypeahead = ({
  name,
  defaultItems,
  ...rest
}) => {
  const state = useFormState()
  const form = useForm()
  const { fields } = useFieldArray(name, {
    initialValue: state.values[name] || emptyArray
  })
  const [items, itemsSet] = React.useState(defaultItems || [])
  const onChange = (val, item) => {
    if (val) {
      fields.push(val)
      itemsSet(existing => ([...existing, item]))
    }
  }

  const removeFromArray = (dx) => {
    fields.remove(dx)
    const newItems = [...items]
    newItems.splice(dx, 1)
    itemsSet(newItems)
  }

  // React.useEffect(() => {
  //   return () => {
  //     form.change(name, [])
  //   }
  // }, [])

  return (
    <div style={{ marginTop: 30, marginBottom: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text fontWeight='bold' fontSize='s' color='dark6'>{rest.label}</Text>
        {/* <a style={{ marginBottom: 20, display: 'block' }} onClick={addToArray}>
          <Text fontSize='s' color='dark6'>Bir tane daha ekle</Text>
        </a> */}
      </div>
      <div style={{marginBottom: 20}}>
      {items.map((item, dx) => {
          return (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: 2,
              my: 3
              }} key={dx}>
              {item.name || item.label}
              <a style={{verticalAlign: 'middle'}} onClick={() => removeFromArray(dx)}><Icon name='circle-remove' color='primary'/></a>
            </Box> 
          )
        })}
      </div>
      <div style={{ paddingLeft: 0 }}>
      <ModelTypeaheadInput {...rest} required={false} requiredLabel={null} onChange={onChange} ignored={fields.value} />
      </div>
    </div>
  )
}

export default ModelTypeahead