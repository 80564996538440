import React from 'react'


function useEventListener(eventName, handler, element = process.browser ? window : null) {
  const handlerRef = React.useRef()

  React.useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  React.useEffect(() => {
    const canUseEventListener = element && element.addEventListener
    if (!canUseEventListener) return

    const eventListener = event => handlerRef.current(event)
    element.addEventListener(eventName, eventListener)
    return () => element.removeEventListener(eventName, eventListener)
  }, [eventName, element])

}

export default useEventListener