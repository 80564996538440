
import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// RunicAura
import { useLocation, useHistory } from 'runic-aura/router'


const RncRouteManager = props => {
  const zones = useSelector(state => state.runicAura.zones)
  const defaultZone = useSelector(state => state.runicAura.defaultZone)
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    const routeParts = location.pathname.split('/')

    if ((routeParts.length == 2) || (routeParts[2].length == 0)) {
      // Tenant Index Page
      if (Object.keys(zones).length == 1 && zones[defaultZone]) {
        history.push(`${zones[defaultZone].indexPath}/`)
      } else if (zones[defaultZone]) {
        history.push(`${zones[defaultZone].indexPath}/`)
      } else {
        console.log('INVALID_ZONE', zones, defaultZone)
      }
    } else if ((routeParts.length == 4) || (routeParts[3].length == 0)) {
      // Zone Index Page
      const zonePath = routeParts[2]
      let selectedZone = null

      Object.keys(zones).forEach(zoneName => {
        const _zone = zones[zoneName]
        if (_zone.path == zonePath && _zone.domains.length > 0) selectedZone = _zone
      })
      if (selectedZone) history.push(`${selectedZone.domains[0].indexPath}/`)
    }
  }, [])

  return (null)
}

export default RncRouteManager
