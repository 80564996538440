import React, { useState, useEffect, useReducer, useRef, useCallback, useMemo } from 'react'

// Vendor
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import stringfy from 'json-stable-stringify'

// Runic
import useAppState from 'runic/hooks/useAppState'
import uiActions from 'runic/systems/ui/actions'

const quickHash = (str) => {
    let result = 0
    if (str.length == 0) return result
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i)
      result = ((result << 5) - result) + char
      result = result & result
    }
    return result
}

export const useFetchSourceData = (elementName, sourceName, reportName, params, parentKey, refreshKey) => {

  const didCancel = useRef(false)
  const [data, setData] = useState(null)
  const [key, setKey] = useState(0)
  const [isFetching, setIsFetching] = useState(true)
  const refresh = useCallback(() => setKey(k => k + 1), [])
  const { user } = useAppState()
  const rcTenantId = params?.rcTenantId || (user && user.rc_tenant_id !== undefined ? user.rc_tenant_id : 1)
  const baseAPIUrl = useSelector((state) => state.core.config.baseAPIUrl || '')
  const baseHeaders = {
    'X-RUNIC-PLATFORM': useSelector((state) => state.core.config.runicPlatform)
  }

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!refreshKey) return
    dispatch(uiActions.startWatch(refreshKey))

    return () => dispatch(uiActions.stopWatch(refreshKey))
  }, [refreshKey])
  const watchVersion = useSelector(state => refreshKey ? state.ui.watch[refreshKey] : null)


  const fetchKey = useMemo(() => quickHash(stringfy({e: elementName, s: sourceName, r: reportName, p: params})), [elementName, sourceName, reportName, params])

  useEffect(() => {
    didCancel.current = false
    const fetchData = async () => {
      setIsFetching(true)
      const result = await axios.get(`/_api/runic/source/${elementName}.${sourceName}.${reportName}`, {params: {'rcTenantId': rcTenantId, ...params}, headers: baseHeaders})
      if (!didCancel.current) {
        setData(result.data)
        setIsFetching(false)
      }
    }
    fetchData()

    return () => {
      didCancel.current = true
      setIsFetching(false)
    }
  }, [fetchKey, key, parentKey || null, watchVersion])

  return [data, refresh, isFetching]
}


export const useFetchSource = (elementName, sourceName, reportName, params, parentKey) => {

  const didCancel = useRef(false)
  const [data, setData] = useState(null)
  const [key, setKey] = useState(0)
  const [isFetching, setIsFetching] = useState(0)
  const refresh = useCallback(() => setKey(key+1), [key])
  const { user } = useAppState()
  const rcTenantId = user && user.rc_tenant_id !== undefined ? user.rc_tenant_id : 1

  const fetchKey = useMemo(() => quickHash(stringfy({e: elementName, s: sourceName, r: reportName, p: params})), [elementName, sourceName, reportName, params])

  useEffect(() => {
    didCancel.current = false
    const fetchData = async () => {
      setIsFetching(true)
      let result
      try {
        result = await axios.get(`/_api/runic/source/${elementName}.${sourceName}.${reportName}`, {params: {'rcTenantId': rcTenantId, ...params, opts:{fetchKey}}})
      } catch(e) {
        result = {}
      }

      if (!didCancel.current) {
        setData(result.data)
        setIsFetching(false)
      }
    }
    fetchData()

    return () => {
      didCancel.current = true
      setIsFetching(false)
    }
  }, [fetchKey, key, parentKey || null])

  return [data, refresh, isFetching]
}