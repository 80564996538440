// Vendor
import { createSelector } from 'reselect'

import { denormalize } from 'normalizr'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import * as changeCase from 'volcano/util/text'


const createEntitySelectors = (entitySchemasByName, models) => {
  const selectors = {}

  Object.keys(entitySchemasByName).forEach(entityCamelCaseName => {
    const entitySchema = entitySchemasByName[entityCamelCaseName]
    const entitySelector = createSelector(
      (state, id) => isFunction(id) ? id(state) : id,
      state => state.entity[entityCamelCaseName].entity,
      (id, entityAll) => (entityAll && entityAll[id])
    )

    selectors[entityCamelCaseName] = entitySelector
  })

  Object.keys(entitySchemasByName).forEach(entityCamelCaseName => {
    const relatedSelectorFuncs = []
    const relatedEntityAllSelectorFuncs = []

    const entitySchema = entitySchemasByName[entityCamelCaseName]
    if (entitySchema.items) {
      return
    }

    const schemaKey = entitySchema._key
    const schema = entitySchema.schema
    // console.log(schema)

    const entitySelector = selectors[entityCamelCaseName]

    const entitySelectorForRelatedSelector = (_, sourceEntity) => sourceEntity
    entitySelectorForRelatedSelector._name = entityCamelCaseName
    relatedSelectorFuncs.push(entitySelectorForRelatedSelector)

    Object.keys(schema).forEach(relatedSnakeCaseName => {
      const relatedCamelCaseName = changeCase.camel(relatedSnakeCaseName)
      const relatedSchema = schema[relatedSnakeCaseName]
      let relatedSelector = selectors[relatedCamelCaseName]

      if (!relatedSelector) {
        const entityPascalCaseName = changeCase.pascal(entityCamelCaseName)
        const modelData = models[entityPascalCaseName]
        if (!modelData) return

        const relationship = modelData.rcr_relationships.find(rel => rel.attr_name == relatedSnakeCaseName)
        if(!relationship) return
        const relatedClsNameFromRelationship = changeCase.camel(relationship.related_cls)
        relatedSelector = selectors[relatedClsNameFromRelationship]
      }

      if (!isArray(relatedSchema)) {
        const relatedEntitySelector = (state, sourceEntity) => {
          return sourceEntity ? relatedSelector(state, sourceEntity[`${relatedSnakeCaseName}_id`]) : null
        }

        relatedEntitySelector._name = relatedCamelCaseName
        relatedEntitySelector._idName = `${relatedSnakeCaseName}_id`
        relatedSelectorFuncs.push(relatedEntitySelector)

        const relatedCamelCaseModelName = changeCase.camel(relatedSchema._key)
        const relatedEntityAllSelector = (state) => state.entity[relatedCamelCaseModelName].entity

        relatedEntityAllSelector._name = relatedCamelCaseName
        relatedEntityAllSelector._idName = `${relatedSnakeCaseName}_id`
        relatedEntityAllSelectorFuncs.push(relatedEntityAllSelector)
      }
    })

    const withDirectRelatedSelector = createSelector(
      relatedSelectorFuncs,
      (sourceEntity, ...relatedEntityList) => {
        if (!sourceEntity) {
          return null
        }
        // console.log('direct related selector', entityCamelCaseName)

        const result = { ...sourceEntity }
        relatedEntityList.forEach((relatedEntity, index) => {
          const argName = relatedSelectorFuncs[index + 1]._name
          result[argName] = relatedEntity
        })

        return result
      }
    )

    const withDirectRelated = (state, id) => {
      const _entity = entitySelector(state, id)
      return withDirectRelatedSelector(state, _entity)
    }

    const denormalizedSelector = (state, id) => {
      u
      // const selectorName = isArray(id) ? `${entityCamelCaseName}List` : entityCamelCaseName

      // const selector = {
      //   [entityCamelCaseName]: id
      // }

      // const denormalized = denormalize(selector, entitySchemasByName, state.orm.entity)
      // return denormalized[entityCamelCaseName]
    }

    const createListSelector = (kind, data) => {
      const _kind = kind || `${entityCamelCaseName}:default`
      const selectors = []

      if (_kind == 'withList') {
        const getIdList = (_, list) => list
        selectors.push(getIdList)

        const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
        selectors.push(getEntityAll)

        const selector = createSelector(
          selectors,
          (idList, entityAll) => {
            if (!idList) return []

            const items = idList.map(id => {
              const entity = entityAll[id]
              return {
                ...entity
              }
            })

            return items
          }
        )
        return selector
      } else if (_kind == 'forOwner') {
        const getOwnerName = (_, ownerName) => ownerName
        selectors.push(getOwnerName)

        const getOwnerId = (_, __, ownerId) => ownerId
        selectors.push(getOwnerId)

        const getContextName = (_, __, ___, contextName) => contextName
        selectors.push(getContextName)

        const getEntityListAll = (state) => state.orm.list[entityCamelCaseName]
        selectors.push(getEntityListAll)

        const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
        selectors.push(getEntityAll)

        if (data && data.withDirectRelated) {

          relatedEntityAllSelectorFuncs.forEach(relatedSelector => selectors.push(relatedSelector))

          const selector = createSelector(
            selectors,
            (ownerName, ownerId, contextName, entityListAll, entityAll, ...relatedSelectorList) => {
              if (ownerName === undefined || ownerName === null || ownerId === undefined || ownerId === null) return []

              const listViewId = entityListAll.meta[contextName || `_related_${ownerName}`]
              const entityList = entityListAll[listViewId]
              if (!entityList || !entityList.items) return []

              const items = entityList.items.map(id => {
                const entity = entityAll[id]
                const result = {
                  ...entity
                }

                relatedSelectorList.forEach((relatedSelector, index) => {
                  const relatedSelectorName = relatedEntityAllSelectorFuncs[index]._name
                  const idName = relatedEntityAllSelectorFuncs[index]._idName
                  const relatedEntityId = entity[idName]
                  result[relatedSelectorName] = relatedSelector[relatedEntityId]
                })

                return result
              })

              return {
                ...entityList,
                items,
              }
            }
          )
          return selector

        } else {

          const selector = createSelector(
            selectors,
            (ownerName, ownerId, entityListAll, entityAll, ...relatedSelectorList) => {
              if (!ownerName || !ownerId) return []

              const entityListById = entityListAll[`byOwner:${ownerName}`]
              if (!entityListById) return []

              const entityList = entityListById[ownerId]
              if (!entityList || !entityList.items) return []

              const items = entityList.items.map(id => {
                const entity = entityAll[id]
                return {
                  ...entity
                }
              })

              return {
                ...entityList,
                items,
              }
            }
          )
          return selector

        }
      } else if (_kind == 'forStaticOwner') {
        const ownerName = data.ownerName
        if (!ownerName) throw new Error('OWNER_NAME_NEEDED_FOR_STATIC_FOR_OWNER_SELECTOR')

        const getOwnerId = (_, ownerId) => ownerId
        selectors.push(getOwnerId)

        const getEntityListById = (state) => state.orm.list[entityCamelCaseName][`byOwner:${ownerName}`]
        selectors.push(getEntityListById)

        const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
        selectors.push(getEntityAll)

        if (data && data.withDirectRelated) {

          relatedEntityAllSelectorFuncs.forEach(relatedSelector => selectors.push(relatedSelector))

          const selector = createSelector(
            selectors,
            (ownerId, entityListById, entityAll, ...relatedSelectorList) => {
              // console.log(ownerId, entityListById, entityAll)
              if (!ownerId) return []
              if (!entityListById) return []

              const entityList = entityListById[ownerId]
              if (!entityList || !entityList.items) return []

              const items = entityList.items.map(id => {
                const entity = entityAll[id]
                const result = {
                  ...entity
                }

                relatedSelectorList.forEach((relatedSelector, index) => {
                  const relatedSelectorName = relatedEntityAllSelectorFuncs[index]._name
                  const idName = relatedEntityAllSelectorFuncs[index]._idName
                  const relatedEntityId = entity[idName]
                  result[relatedSelectorName] = relatedSelector[relatedEntityId]
                })

                return result
              })

              return {
                ...entityList,
                items,
              }
            }
          )
          return selector

        } else {
          const selector = createSelector(
            selectors,
            (ownerId, entityListById, entityAll) => {
              if (!ownerId) return []
              if (!entityListById) return []

              const entityList = entityListById[ownerId]
              if (!entityList || !entityList.items) return []

              const items = entityList.items.map(id => {
                const entity = entityAll[id]
                return {
                  ...entity
                }
              })

              return {
                ...entityList,
                items,
              }
            }
          )
          return selector
        }

      } else {
        if (data && data.withDirectRelated) {
          const getIdList = (state) => state.orm.list[entityCamelCaseName][state.orm.list[entityCamelCaseName].meta[kind]]
          selectors.push(getIdList)

          const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
          selectors.push(getEntityAll)

          relatedEntityAllSelectorFuncs.forEach(relatedSelector => selectors.push(relatedSelector))

          const selector = createSelector(
            selectors,
            (idList, entityAll, ...relatedSelectorList) => {
              if (!idList || !idList.items) return []

              const items = idList.items.map(id => {
                const entity = entityAll[id]

                const result = {
                  ...entity
                }

                relatedSelectorList.forEach((relatedSelector, index) => {
                  const relatedSelectorName = relatedEntityAllSelectorFuncs[index]._name
                  const idName = relatedEntityAllSelectorFuncs[index]._idName
                  const relatedEntityId = entity[idName]
                  result[relatedSelectorName] = relatedSelector[relatedEntityId]
                })
                
                return result
              })

              return {
                ...idList,
                items,
              }

            }
          )

          return selector
        }

        const getIdList = (state) => state.orm.list[entityCamelCaseName][state.orm.list[entityCamelCaseName].meta[kind]]
        selectors.push(getIdList)

        const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
        selectors.push(getEntityAll)

        const selector = createSelector(
          selectors,
          (idList, entityAll) => {
            if (!idList || !idList.items) return []

            const items = idList.items.map(id => {
              const entity = entityAll[id]
              return {
                ...entity
              }
            })

            return {
              ...idList,
              items,
            }

          }
        )
        return selector
      }
    }

    const createListSelectorWithFilter = () => {
      const selectors = []

      const getFilter = (_, filter) => filter
      selectors.push(getFilter)

      const getEntityLists = (state) => state.orm.list[entityCamelCaseName]
      selectors.push(getEntityLists)

      const getEntityAll = (state) => state.entity[entityCamelCaseName].entity
      selectors.push(getEntityAll)

      const selector = createSelector(
        selectors,
        (filter, entityLists, entityAll) => {
          if (!entityLists) return []

          const idListForFilter = entityLists[filter]
          if (!idListForFilter || !idListForFilter.items) return []

          const items = idListForFilter.items.map(id => {
            const entity = entityAll[id]
            return {
              ...entity
            }
          })

          return {
            ...idListForFilter,
            items,
          }

        }
      )
      return selector

    }

    const createDynamicListSelector = createSelector(
      kind => kind,
      kind => createListSelector(kind, {withDirectRelated: true})
    )

    const defaultListSelector = createListSelector('default')
    const defaultListSelectorWithDirectRelated = createListSelector('default', {withDirectRelated: true})
    const defaultForOwnerSelector = createListSelector('forOwner', {withDirectRelated: true})

    entitySelector.createSelector = (idOrFunc) => {
      const selector = (state) => {
        const id = isFunction(idOrFunc) ? idOrFunc(state) : id
        return entitySelector(state, id)
      }
      selector.withDirectRelated = (state) => {
        const id = isFunction(idOrFunc) ? idOrFunc(state) : id
        return withDirectRelated(state, id)
      }
      selector.denormalized = (state) => {
        const id = isFunction(idOrFunc) ? idOrFunc(state) : id
        return denormalizedSelector(state, id)
      }

      return selector
    }

    entitySelector.withDirectRelated = withDirectRelated
    entitySelector.denormalized = denormalizedSelector
    entitySelector.createListSelector = createListSelector
    entitySelector.createDynamicListSelector = createDynamicListSelector
    entitySelector.listSelectorWithFilter = createListSelectorWithFilter()
    entitySelector.listSelector = defaultListSelector
    entitySelector.listSelectorWithDirectRelated = defaultListSelectorWithDirectRelated
    entitySelector.forOwnerSelector = defaultForOwnerSelector

    entitySelector.getEntityMetadata = createSelector(
      state => state.orm.entityMetadata,
      (_, entityName) => entityName,
      (entityMetadata, entityName) => entityMetadata[changeCase.lowerCaseFirst(entityName)]
    )

  })

  return selectors
}


export default createEntitySelectors
export const getEntitySelector = (state, entityName) => state.model.entitySelectors[changeCase.camel(entityName)]