import React from 'react'

// Reverb
import { ContentHeader } from 'volcano/components/Content'
import Text from 'volcano/components/Text'
import Card from 'volcano/components/Card'

// Riva
import useDirector from 'runic/hooks/useDirector'
import { useFetchSourceData } from 'runic/hooks/source'


const SettingsHome = props => {
  const [settingsData] = useFetchSourceData('RcApp', 'AppSettings', 'settings')
  if (!settingsData || !settingsData.settings_data) return null
  const categories = settingsData.settings_data.categories
  return (
    <>
      <ContentHeader>
        <Text>Ayarlar</Text>
      </ContentHeader>

      <Card css={{ boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)" }}>
        {Object.keys(categories).map((categoryKey, dx) => {
          const category = categories[categoryKey]
          return (
            <div key={dx}>
              {category.name}

              {Object.keys(category.sections).map((sectionKey, sectionDx) => {
                const section = category.sections[sectionKey]
                console.log(section)
                return (
                  <div key={sectionDx}>
                    {section.name}

                    {Object.keys(section.items).map((itemKey, itemDx) => {
                      return <SettingsItem key={itemDx} item={section.items[itemKey]} baseKey={`${categoryKey}.${sectionKey}.${itemKey}`} />
                    })}
                  </div>
                )
              })}
            </div>
          )
        })}
      </Card>
    </>
  )
}

const SettingsItem = ({ item, baseKey }) => {
  const director = useDirector()
  const componentName = item.data.ui.rncui.componentName
  const IndexComponent = director.components[componentName]
  if (!IndexComponent) return null
  return (
    <div>
      <IndexComponent {...item.data.ui.rncui.componentProps} baseKey={baseKey} />
    </div>
  )
}

export default SettingsHome