import React from 'react'

const useOnClickOutside = (ref, handler, useParent) => {
  React.useEffect(() => {
    const listener = event => {
      if (!ref.current) return
      const holder = useParent ? ref.current.parentNode : ref.current
      if (holder.contains(event.target)) return
      // console.log('***', 'close', holder, ref.current, event.target)
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside