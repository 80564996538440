import React from 'react'

// Vendor
import { useHistory } from "runic-aura/router"

// Runic
import useAppState from 'runic/hooks/useAppState'


const RncTenantSelector = props => {
  const history = useHistory()

  const { user } = useAppState()
  console.log(user)

  React.useEffect(() => {
    if (user && user.rc_tenant_id !== undefined) history.replace(`/${user.rc_tenant_id}/`)
  }, [user])

  return null
}

export default RncTenantSelector
