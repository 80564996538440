import React from 'react'

// Vendor
import * as yup from 'yup'

// Reverb
import { TextField, CheckboxField , DropdownField} from 'runic-aura/components/RunicField'
import Text from 'volcano/components/Text'
import ModelTypeahead from './ModelTypeahead'


const ModelFormField = ({
  field,
  index
}) => {
  let schema
  if (!field.optional) schema = yup.string().required()

  const focus = index === 0

  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  }

  console.log('--', field.fieldKind)
  if (field.fieldKind == 'BOOL') return (
    <CheckboxField name={field.name} label={field.label}/>
  )

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )
  // <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus />
  //     <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
  //     <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
}

export default ModelFormField