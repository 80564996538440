import React, { Children } from 'react'

// Vendor
import { BrowserRouter as Router, __RouterContext as RouterContext } from "react-router-dom"
import { ImmutableRouterContext, LocationRouterContext, MatchRouterContext } from '../RncRouterContext'

const RncRouter = props => {
  const reactRouterContext = React.useContext(RouterContext)
  const immutableCtxRef = React.useRef(null)

  if (!reactRouterContext) return null

  const { history, location, match, staticContext } = reactRouterContext

  if (immutableCtxRef.current === null) immutableCtxRef.current = { history, staticContext }

  return (
    <ImmutableRouterContext.Provider value={immutableCtxRef.current}>
      <LocationRouterContext.Provider value={location}>
        <MatchRouterContext.Provider value={match}>
          {props.children}
        </MatchRouterContext.Provider>
      </LocationRouterContext.Provider>
    </ImmutableRouterContext.Provider>
  )
}

const WrapperRouter = props => (
  <Router>
    <RncRouter {...props} />
  </Router>
)

export default WrapperRouter