// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'
import coreActions from 'runic/systems/core/actions'

// Rncui
import runicAuraActions from './actions'

const defaultState = {
  zones: {},
  units: {},
  defaultZone: null,
  components: {},
  activeZoneName: null,
  activeDomainName: null,
  activeUnitName: null,
  activeModelData: null,
  activeZoneParams: null,
  config: {},
  directory: {},
  context: {},
}

export default createReducer(defaultState, {
  [coreActions.RCR_RIVA_TENANT_SETUP]: {
    success: (draft, { uiData }) => {
      const { defaultZone, units, zones } = uiData

      draft.zones = zones
      draft.units = units
      draft.defaultZone = defaultZone
    }
  },

  [runicAuraActions.RNC_ACTIVATE_ZONE]: (draft, { zoneName, zoneParams }, state) => {
    draft.activeZoneName = zoneName
    draft.activeZoneParams = zoneParams
  },

  [runicAuraActions.RNC_ACTIVATE_DOMAIN]: (draft, { domainName }, state) => draft.activeDomainName = domainName,

  [runicAuraActions.RNC_ACTIVATE_UNIT]: (draft, { unitName }, state) => draft.activeUnitName = unitName,

  [runicAuraActions.RNC_ACTIVATE_DETAIL]: (draft, payload) => draft.activeModelData = payload,
  [runicAuraActions.RNC_DEACTIVATE_DETAIL]: (draft) => draft.activeModelData = null,

  [runicAuraActions.RNC_ACTIVATE_SUB_DETAIL]: (draft, payload, state) => {
    if (state.activeModelData) draft.activeModelData = {...state.activeModelData, subDetail: payload}
  },
  [runicAuraActions.RNC_DEACTIVATE_SUB_DETAIL]: (draft, _, state) => {
    if (state.activeModelData && state.activeModelData.subDetail) delete draft.activeModelData.subDetail
  },

  [runicAuraActions.setupUi]: (draft, { config }, state) => draft.config = config,

  [runicAuraActions.RNC_REGISTER_COMPONENTS]: (draft, { components }, state) => draft.components = components,

  [runicAuraActions.RNC_MODIFY_DIRECTORY]: (draft, payload, state) => draft.directory = {...draft.directory, ...payload},

  [runicAuraActions.RNC_MODIFY_CONTEXT]: (draft, payload, state) => draft.context = {...draft.context, ...payload},
})
