// Riva - Function
import { createActions } from 'runic/core/redux'

const ACTIONS = {
  RCR_ACTION: {
    RCR_CREATE_ENTITY: {},
    RCR_DELETE_ENTITY: {},
    RCR_DELETE_MULTIPLE_ENTITY: {},
    RCR_ARCHIVE_ENTITY: {},
    RCR_ARCHIVE_MULTIPLE_ENTITY: {},
  },

  LOCAL: {
    RCR_SETUP_COMPONENTS: {},
    RCR_REGISTER_RECRAFT_MODELS: {},
    RCR_REGISTER_RECRAFT_SCHEMAS: {},
    RCR_MODEL_START_WATCH: {},
    RCR_MODEL_STOP_WATCH: {},
  },
}

export default createActions(ACTIONS, 'Master')
