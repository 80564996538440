// Riva - Function
import { createActions } from 'runic/core/redux'

const BASE_COMPONENT = 'RcApp'

const ACTIONS = {
  RCR_ACTION: {
    UPDATE_SETTING: {}
  },
}

export default createActions(ACTIONS, 'settings', BASE_COMPONENT)
