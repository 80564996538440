import axios from 'axios'

import { select } from 'redux-saga/effects'


export function* runicSourceRequest(sourceName, data = {}, sourceUrlPath='source') {
  const dataToSend = {...data}

  const baseAPIUrl = yield select((state) => state.core.config.baseAPIUrl || '')

  if (!dataToSend.rcTenantId) {
    dataToSend.rcTenantId = yield select((state) => (state.core.config.isMultiTenant ? state.core.activeRcTenantId : 1))
  } else if (dataToSend.rcTenantId == '__DISABLE') {
    dataToSend.rcTenantId = null
  }
  
  const baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.core.config.runicPlatform)
  }

  if (!process.browser) {
    const req = yield select((state) => state.core.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.get(`${baseAPIUrl}/_api/runic/${sourceUrlPath}/${sourceName}`, {params: dataToSend, headers: {...baseHeaders, ...headers} })
  } else {
    return yield axios.get(`${baseAPIUrl}/_api/runic/${sourceUrlPath}/${sourceName}`, {params: dataToSend, headers: baseHeaders})
  }
  
}

export function* runicActionRequest(action, data = {}, kind = 'action') {
  const dataToSend = {...data}
  const urlParams = {}

  const baseAPIUrl = yield select((state) => state.core.config.baseAPIUrl || '')
  const baseHeaders = {
    'X-RUNIC-PLATFORM': yield select((state) => state.core.config.runicPlatform)
  }

  if (dataToSend._rcTenantId) {
    if (dataToSend._rcTenantId !== '__DISABLE') urlParams.rcTenantId = `${dataToSend._rcTenantId}`
    else delete dataToSend._rcTenantId
  } else if (dataToSend.rcTenantCodeName) {
    urlParams.rcTenantCodeName = `${data.rcTenantCodeName}`
    delete dataToSend.rcTenantCodeName
  } else {
    urlParams.rcTenantId = yield select((state) => (state.core.config.isMultiTenant ? state.core.activeRcTenantId : 1))
  }

  if (!process.browser) {
    const req = yield select((state) => state.core.req)
    const headers = req && req.headers.cookie ? {Cookie: req.headers.cookie} : {}
    return yield axios.post(`${baseAPIUrl}/_api/runic/${kind}/${action}`, dataToSend, {headers: {...baseHeaders, ...headers}, params: urlParams})
  } else {
    return yield axios.post(`${baseAPIUrl}/_api/runic/${kind}/${action}`, dataToSend, {params: urlParams, headers: baseHeaders})
  }
}

export const callCallbacks = (action, kind, payload) => {
  const _kind = kind.toLowerCase()
  if (action.callbacks && action.callbacks[_kind]) {
    return action.callbacks[_kind](payload)
  }
}
