import React from 'react'

// Vendor
import { useSelector } from 'react-redux'
import axios from 'axios'

// Riva
import useAppState from 'runic/hooks/useAppState'
import { createRReducer } from 'runic/hooks/util'

// Vendor
import isNumber from 'lodash/isNumber'

const toLower = (value) => (value === null || value === undefined ? '' : value).toString().toLowerCase();

export const getOptionLabel = option => option.label

export const getBestMatchingOption = (inputValue, options) => {
  if (!inputValue || inputValue.length == 0) return {}
  const valueLower = toLower(inputValue)

  const foundOptions = options.reduce((result, option, index) => {
    if (option && option.disabled) return result
    if (!option || option.value == undefined || option.value == null) return result

    const optionValue = option.value
    const optionLabel = option.label.toString()
    const optionLabelLower = toLower(optionLabel)

    const matchPosition = optionLabelLower.indexOf(valueLower)

    if (matchPosition != -1) {
      const optionResult = {
        option,
        optionLabelLower,
        matchPosition,
        index
      }
      return [...result, optionResult]
    } else {
      return result
    }
  }, []);

  foundOptions.sort((a, b) => {
    const positionDifference = a.matchPosition - b.matchPosition
    if (positionDifference) return positionDifference

    const isAComesBeforeBAlphabetically = a.optionLabelLower > b.optionLabelLower
    return isAComesBeforeBAlphabetically ? 1 : -1
  });

  return foundOptions.length ? foundOptions[0] : {};
}

export const getPreviousIndex = (currentIndex, list) => {
  const listLength = list.length

  if (!listLength) return null
  if (!isNumber(currentIndex)) return listLength - 1
  if (currentIndex === 0) return listLength - 1

  for (let i = currentIndex - 1; i >= currentIndex - listLength; i--) {
    const listIndexOfI = i < 0 ? i + listLength - 1 : i
    if (list[listIndexOfI] && !list[listIndexOfI].disabled) return listIndexOfI
  }

  return currentIndex
}

export const getNextIndex = (currentIndex, list) => {
  const listLength = list.length

  if (!listLength) return null
  if (!isNumber(currentIndex)) return 0
  if (currentIndex === listLength - 1) return 0

  for (let i = currentIndex + 1; i <= listLength + currentIndex; i++) {
    const listIndexOfI = i >= listLength ? i - listLength : i
    if (list[listIndexOfI] && !list[listIndexOfI].disabled) return listIndexOfI
  }

  return currentIndex
}

export const dataFetchReducer = createRReducer({
  INPUT_CHANGE: (draft, payload) => {
    draft.inputValue = payload
  },

  SELECT: (draft, payload) => {
    draft.selected = payload
    draft.inputValue = payload.label
    draft.isOpen = false
    draft.onChange(payload)
    if (draft.resetInputOnSelect) draft.inputValue = ''
  },

  CHANGE: (draft, payload) => {
    draft.selected = payload
    draft.inputValue = payload.label
    draft.onChange(payload)
    if (draft.resetInputOnSelect) draft.inputValue = ''
  },

  OPTION_CHANGE: (draft, payload) => {
    const options = getOptionsFromResponse(payload)
    draft.options = options
    // draft.isOpen = true
  },

  OPTION_CHANGE_WITH_SELECT: (draft, payload) => {
    const options = getOptionsFromResponse(payload)
    const selected = options[0]
    if (selected) {
      draft.selected = selected
      draft.inputValue = selected.label
    }
    draft.options = options
    draft.isOpen = false
    draft.onChange(selected)
    if (draft.resetInputOnSelect) draft.inputValue = ''
  },

  OPEN: (draft) => {
    draft.isOpen = true
  },

  RESET: (draft) => {
    draft.isOpen = true
    draft.selected = null
    draft.onChange(null)
  },

  CLOSE: (draft) => {
    draft.isOpen = false
  },

  SET_OPEN: (draft, payload) => {
    draft.isOpen = payload
  },
})

const getOptionsFromResponse = (response, getProps) => response && response.items ? response.items.map(item => {
  const option = { value: item.id, label: item.identifier, item: item.item }
  return ({ ...option, props: getProps && getProps(option) })
}) : []

const defaultSettings = {
  autoSelect: true,
  ignored: null
}

const useTypeahead = (
  modelName,
  onChange,
  settings = defaultSettings
) => {
  const { autoSelect, params: incomingParams, filters: incomingFilters, initialValue, resetInputOnSelect } = settings

  const [internalState, internalDispatch] = React.useReducer(dataFetchReducer, {
    options: [],
    selected: null,
    inputValue: '',
    isOpen: false,
    onChange,
    resetInputOnSelect
  })

  // console.log('***', internalState)

  const didCancel = React.useRef(false)
  const [isFetching, setIsFetching] = React.useState(0)
  const [isInitialQueryDone, isInitialQueryDoneSet] = React.useState((initialValue === undefined || initialValue === null || initialValue === ''))

  const isOpen = internalState.isOpen
  const setIsOpen = (value) => internalDispatch({kind: 'SET_OPEN', payload: value})

  const inputValue = internalState.inputValue
  const selected = internalState.selected
  const options = internalState.options

  const inputRef = React.useRef()

  const modelData = useSelector(state => state.model.models[modelName])

  const elementName = modelData.element
  const sourceName = modelData.name
  const queryName = 'Typeahead'

  const { user } = useAppState()
  const rcTenantId = user && user.rc_tenant_id !== undefined ? user.rc_tenant_id : 1

  React.useEffect(() => {
    if (!inputRef.current) return
    if (inputRef.current == document.activeElement) {
      console.log(inputRef.current)
      console.log(document.activeElement)
      // setIsOpen(true)
    }
  }, [inputRef.current])

  React.useEffect(() => {
    if ((inputValue === undefined || inputValue === null) && (initialValue === undefined || initialValue === null)) return
    if (selected && inputValue === selected.label) return

    didCancel.current = false

    const params = {
      value: inputValue,
      ...incomingParams,
      filters: incomingFilters
    }

    let isInitialQuery = false

    if (!isInitialQueryDone) {
      isInitialQuery = true
      params.value = initialValue
      params.field = 'id'
      isInitialQueryDoneSet(true)
    }

    const fetchData = async () => {
      setIsFetching(true)
      let result

      try {
        result = await axios.get(`/_api/runic/source/${elementName}.${sourceName}.${queryName}`, { params: { 'rcTenantId': rcTenantId, ...params } })
      } catch (e) {
        console.log(e)
        result = {}
      }

      if (!didCancel.current) {
        setIsFetching(false)
        let finalData = result.data
        if (settings.ignored) {
          finalData = {
            items: []
          }
          result.data && result.data.items && result.data.items.forEach(resultItem => {
            if (!settings.ignored.find(x => x === resultItem.id)) {
              finalData.items.push(resultItem)
            }
          })
        }
        if (isInitialQuery) {
          internalDispatch({kind: 'OPTION_CHANGE_WITH_SELECT', payload: finalData})
        } else {
          internalDispatch({kind: 'OPTION_CHANGE', payload: finalData})
        }
      }
    }
    fetchData()

    return () => {
      didCancel.current = true
      setIsFetching(false)
    }
  }, [inputValue, isInitialQueryDone, settings.ignored])

  // console.log('data', data, options, selected, isOpen)

  const handleArrowUp = e => {
    e.preventDefault()
    const selectedIndex = options.findIndex(opt => selected && opt.value === selected.value)

    let previousIndex
    if (selectedIndex === -1) previousIndex = options.length - 1
    else previousIndex = getPreviousIndex(selectedIndex, options)

    if (!options[previousIndex]) return
    internalDispatch({kind: 'CHANGE', payload: options[previousIndex]})
  }

  const handleArrowDown = e => {
    e.preventDefault()
    const selectedIndex = options.findIndex(opt => selected && opt.value === selected.value)

    let nextIndex
    if (selectedIndex === -1) nextIndex = 0
    else nextIndex = getNextIndex(selectedIndex, options)

    if (!options[nextIndex]) return
    internalDispatch({kind: 'CHANGE', payload: options[nextIndex]})
  }

  const handleEnter = (e) => {
    e.preventDefault()
    const {option} = getBestMatchingOption(inputValue, options)
    if (option) internalDispatch({kind: 'SELECT', payload: option})
  }

  const keyHandlers = {
    ArrowUp: handleArrowUp,
    ArrowDown: handleArrowDown,
    Enter: handleEnter
  }

  const focusHandler = () => {
    internalDispatch({kind: 'OPEN'})
    inputRef.current.select()
  }

  const blurHandler = () => {
    internalDispatch({kind: 'CLOSE'})
  }

  const changeHandler = (e) => {
    internalDispatch({kind: 'INPUT_CHANGE', payload: e.target.value})
  }

  const keyDownHandler = e => {
    const eventKeyCode = e.key
    if (keyHandlers[eventKeyCode]) keyHandlers[eventKeyCode](e)
  }

  const inputProps = {
    value: internalState.inputValue,
    onFocus: focusHandler,
    onChange: changeHandler,
    onKeyDown: keyDownHandler,
    onBlur: blurHandler,
    refs: [inputRef],
  }

  const popoverProps = {
    isOpen,
    setIsOpen
  }

  const getOptionProps = (option, selected) => {
    if (option.value == '@@R.NO_RESULT') {
      return ({

      })
    }
    return ({
      onMouseDown: (e) => {
        e.nativeEvent.stopImmediatePropagation()
        internalDispatch({kind: 'SELECT', payload: option})
      },
      selected: selected && option.value === selected.value
    })
  }

  let finalOptions = options

  if (!options || options.length == 0) finalOptions = [{
    label: 'Sonuç bulunamadı',
    value: '@@R.NO_RESULT'
  }]

  const onCreate = id => {
    didCancel.current = false

    const params = {
      value: id,
      field: 'id',
      ...incomingParams,
      filters: incomingFilters
    }

    const fetchData = async () => {
      setIsFetching(true)
      let result
      try {
        result = await axios.get(`/_api/runic/source/${elementName}.${sourceName}.${queryName}`, { params: { 'rcTenantId': rcTenantId, ...params } })
      } catch (e) {
        console.log(e)
        result = {}
      }

      if (!didCancel.current) {
        setIsFetching(false)
        internalDispatch({kind: 'OPTION_CHANGE_WITH_SELECT', payload: result.data})
      }
    }
    fetchData()
  }
  const open = () => internalDispatch({kind: 'OPEN'})
  const reset = () => internalDispatch({kind: 'RESET'})
  // console.log(modelName, internalState)

  return [finalOptions, selected, popoverProps, inputProps, getOptionProps, inputRef, {onCreate, open, reset}]
}

export default useTypeahead