import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import { getModelData, getEntityMetadata } from 'runic/systems/model/selectors'
import { getActionGroup, getActionMap } from 'runic/systems/action/selectors'
import actionGroupActions from 'runic/systems/action/actions'
import useRunicAction from 'runic/hooks/useRunicAction'
import useDirector from 'runic/hooks/useDirector'

// // Rncui
import QuickForm from 'runic-aura/components/QuickForm'

// Reverb
import { Menu, MenuItem } from 'volcano/components/Menu'
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import Alert from 'volcano/components/Alert'
import { Loading } from 'volcano/components/Progress'
import { Box } from 'volcano'
import Modal from 'volcano/components/Modal'


const getActionLabelText = action => {
  let labelText = action.ui && action.ui.label

  if (!labelText) {
    if (action.kind == 'DELETE') labelText = 'btn.delete'
    else if (action.kind == 'ARCHIVE') labelText = 'archive'
    else if (action.kind == 'MULTIPLE_DELETE') labelText = 'delete'
    else if (action.kind == 'MULTIPLE_ARCHIVE') labelText = 'archive'
  }

  return labelText
}


const MenuContent = ({
  actions,
  modelData,
  setAction
}) => {
  const items = []

  if (!actions) return null

  actions.forEach((actionData, index) => {
    if (actionData.predicate && !actionData.predicate(entity)) return null
    if (!actionData.ui) return null

    const label = <Text id={getActionLabelText(actionData)} textCase='title' />

    items.push(
      <MenuItem key={`act${index}`} onClick={() => setAction(actionData)}>
        {label}
      </MenuItem>
    )
  })

  if (items.length == 0) return (<Box p={3}><Text>İşlem yok.</Text></Box>)
  else {
    return items
  }
}

const MenuAction = ({
  action,
  entity,
  modelData,
  setAction,
  actionCallbacks
}) => {
  let content
  if (action.kind === 'Operation') {
    content = <QuickFormAction action={action} entity={entity} modelData={modelData} setAction={setAction} actionCallbacks={actionCallbacks} />
  }

  return (
    <div>
      {content}
    </div>
  )
}

const DynamicComponentForm = props => {
  const director = useDirector()
  const Component = director.components[props.action.ui.component_name]
  const modalHolderRef = React.useRef()

  return (
    <>
    <div ref={modalHolderRef}/>
    <Modal defaultIsOpen parentSelector={() => modalHolderRef.current}>
      <Component {...props} onClose={props.onClose}/>
    </Modal>
    </>
  )
}

const QuickFormAction = ({
  action,
  entity,
  modelData,
  setAction,
  actionCallbacks
}) => {
  const buttonLabel = action.ui.button_label || 'save'
  const submitAction = useRunicAction(action, modelData)

  const handleClose = () => setAction(null)
  const handleSuccess = (payload) => {
    setAction(null, 'Başarılı')
    if (actionCallbacks.success) {
      Object.keys(actionCallbacks.success).forEach(key => {
        const cb = actionCallbacks.success[key]
        if (key == 'ALL') cb(payload)
      })
    }
  }

  let formData = action.data || {}
  if (action.getData) formData = { ...formData, ...action.getData(entity) }
  formData.id = entity.id

  let content

  if (action.ui.component_name) {
    content = (
      <DynamicComponentForm
        entity={entity}
        buttonLabel={buttonLabel}
        submitAction={submitAction}
        action={action}
        formData={formData}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
    )
  } else {
    content = (
      <QuickForm
        entity={entity}
        buttonLabel={buttonLabel}
        submitAction={submitAction}
        fields={action.ui.fields}
        formData={formData}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
    )
  }

  return (
    <Box p={3} width={300}>
      {action.message && (
        <div>
          <Text id={action.message} textCase='title' />
        </div>
      )}
      {content}
    </Box>
  )
}

const EntityActionGroupMenuContent = ({
  entity,
  modelName,
  actionCallbacks = {},
  actionGroupKind = 'Entity'
}) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [state, setState] = React.useState({ action: null, message: null })
  const { action, message } = state

  const setAction = React.useCallback((_action, _message) => setState({ action: _action, message: _message }), [])

  const modelData = useSelector(state => getModelData(state, modelName))
  const [actionGroup, actions] = useSelector(state => getActionGroup(state, modelData.identifier, actionGroupKind, entity.id))
  // const entityMetadata = useSelector(state => getEntityMetadata(state, modelName))

  const dispatch = useDispatch()

  React.useEffect(() => {
    const actionGroupParams = {
      actionGroupKind: actionGroupKind,
      modelIdentifier: modelData.identifier,
      entityId: entity.id,
    }
    dispatch(actionGroupActions.rcrGetActionGroupsForModel(actionGroupParams, {
      success: (payload) => setIsLoading(false)
    }))
  }, [])

  if (isLoading) return (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  let content
  if (action) {
    content = (
      <MenuAction action={action} entity={entity} modelData={modelData} setAction={setAction} actionCallbacks={actionCallbacks} />
    )
  } else {
    content = <MenuContent actions={actions} modelData={modelData} setAction={setAction} />
  }

  return (
    <div>
      {message && (
        <Box textAlign={'center'} p={2}>
          <Alert kind='success'> <Icon name='circle-empty-check' color='light'/><Text color='light'>{message}</Text> </Alert>
        </Box>
      )}
      {content}
    </div>
  )
}


const EntityActionGroupMenu = props => {
  return (
    <Menu anchor={<a><Icon name='more' /></a>}>
      <EntityActionGroupMenuContent {...props} />
    </Menu>
  )
}

export default EntityActionGroupMenu