import React from 'react'

import { createRReducer } from './util'

// Vendor
import isNumber from 'lodash/isNumber'

const toLower = (value) => (value === null || value === undefined ? '' : value).toString().toLowerCase();

export const getOptionLabel = option => option.label

export const getBestMatchingOption = (inputValue, options) => {
  if (!inputValue || inputValue.length == 0) return {}
  const valueLower = toLower(inputValue)

  const foundOptions = options.reduce((result, option, index) => {
    if (option && option.disabled) return result
    if (!option || option.value == undefined || option.value == null) return result

    const optionValue = option.value
    const optionLabel = option.label.toString()
    const optionLabelLower = toLower(optionLabel)

    const matchPosition = optionLabelLower.indexOf(valueLower)

    if (matchPosition != -1) {
      const optionResult = {
        option,
        optionLabelLower,
        matchPosition,
        index
      }
      return [...result, optionResult]
    } else {
      return result
    }
  }, []);

  foundOptions.sort((a, b) => {
    const positionDifference = a.matchPosition - b.matchPosition
    if (positionDifference) return positionDifference

    const isAComesBeforeBAlphabetically = a.optionLabelLower > b.optionLabelLower
    return isAComesBeforeBAlphabetically ? 1 : -1
  });

  return foundOptions.length ? foundOptions[0] : {};
}

export const getPreviousIndex = (currentIndex, list) => {
  const listLength = list.length

  if (!listLength) return null
  if (!isNumber(currentIndex)) return listLength - 1
  if (currentIndex === 0) return listLength - 1

  for (let i = currentIndex - 1; i >= currentIndex - listLength; i--) {
    const listIndexOfI = i < 0 ? i + listLength - 1 : i
    if (list[listIndexOfI] && !list[listIndexOfI].disabled) return listIndexOfI
  }

  return currentIndex
}

export const getNextIndex = (currentIndex, list) => {
  const listLength = list.length

  if (!listLength) return null
  if (!isNumber(currentIndex)) return 0
  if (currentIndex === listLength - 1) return 0

  for (let i = currentIndex + 1; i <= listLength + currentIndex; i++) {
    const listIndexOfI = i >= listLength ? i - listLength : i
    if (list[listIndexOfI] && !list[listIndexOfI].disabled) return listIndexOfI
  }

  return currentIndex
}

export const dataFetchReducer = createRReducer({
  INPUT_CHANGE: (draft, payload) => {
    draft.inputValue = payload
  },

  SELECT: (draft, payload) => {
    draft.selected = payload
    draft.inputValue = payload.label
    draft.isOpen = false
    draft.onChange(payload)
  },

  CHANGE: (draft, payload) => {
    draft.selected = payload
    draft.inputValue = payload.label
    draft.onChange(payload)
    console.log(draft.onChange, payload)
  },

  OPTION_CHANGE: (draft, payload) => {
    const options = getOptionsFromResponse(payload)
    draft.options = options
    draft.isOpen = true
  },

  OPEN: (draft) => {
    draft.isOpen = true
  },

  CLOSE: (draft) => {
    draft.isOpen = false
  },

  SET_OPEN: (draft, payload) => {
    draft.isOpen = payload
  },
})

const getOptionsFromResponse = (response, getProps) => response.items.map(item => {
  const option = { value: item.id, label: item.identifier, item: item.item }
  return ({ ...option, props: getProps && getProps(option) })
})

const defaultSettings = {
  autoSelect: true
}

const useDropdownTypeahead = (
  options,
  onChange,
  settings = defaultSettings
) => {
  const { autoSelect } = settings

  const [internalState, internalDispatch] = React.useReducer(dataFetchReducer, {
    options,
    selected: null,
    inputValue: '',
    isOpen: false,
    onChange
  })

  // console.log('***', internalState)

  const didCancel = React.useRef(false)
  const [isFetching, setIsFetching] = React.useState(0)
  
  const isOpen = internalState.isOpen
  const setIsOpen = (value) => internalDispatch({kind: 'SET_OPEN', payload: value})

  const inputValue = internalState.inputValue
  const selected = internalState.selected

  const inputRef = React.useRef()

  React.useEffect(() => {
    if (!inputRef.current) return
    if (inputRef.current == document.activeElement) {
      setIsOpen(true)
    }
  }, [inputRef.current])

  // console.log('data', data, options, selected, isOpen)

  const handleArrowUp = e => {
    e.preventDefault()
    const selectedIndex = options.findIndex(opt => selected && opt.value === selected.value)

    let previousIndex
    if (selectedIndex === -1) previousIndex = options.length - 1
    else previousIndex = getPreviousIndex(selectedIndex, options)

    if (!options[previousIndex]) return
    internalDispatch({kind: 'CHANGE', payload: options[previousIndex]})
  }

  const handleArrowDown = e => {
    e.preventDefault()
    const selectedIndex = options.findIndex(opt => selected && opt.value === selected.value)

    let nextIndex
    if (selectedIndex === -1) nextIndex = 0
    else nextIndex = getNextIndex(selectedIndex, options)

    if (!options[nextIndex]) return
    internalDispatch({kind: 'CHANGE', payload: options[nextIndex]})
  }

  const handleEnter = (e) => {
    e.preventDefault()
    const {option} = getBestMatchingOption(inputValue, options)
    if (option) internalDispatch({kind: 'SELECT', payload: option})
  }

  const keyHandlers = {
    ArrowUp: handleArrowUp,
    ArrowDown: handleArrowDown,
    Enter: handleEnter
  }

  const focusHandler = () => {
    internalDispatch({kind: 'OPEN'})
    inputRef.current.select()
  }

  const blurHandler = () => {
    internalDispatch({kind: 'CLOSE'})
  }

  const changeHandler = (e) => {
    internalDispatch({kind: 'INPUT_CHANGE', payload: e.target.value})
  }

  const keyDownHandler = e => {
    const eventKeyCode = e.key
    if (keyHandlers[eventKeyCode]) keyHandlers[eventKeyCode](e)
  }

  const inputProps = {
    value: internalState.inputValue,
    onFocus: focusHandler,
    onChange: changeHandler,
    onKeyDown: keyDownHandler,
    onBlur: blurHandler,
    refs: [inputRef],
  }

  const popoverProps = {
    isOpen,
    setIsOpen
  }

  const getOptionProps = (option, selected) => ({
    onMouseDown: (e) => {
      e.nativeEvent.stopImmediatePropagation()
      internalDispatch({kind: 'SELECT', payload: option})
    },
    selected: selected && option.value === selected.value
  })

  return [options, selected, popoverProps, inputProps, getOptionProps, inputRef]
}

export default useDropdownTypeahead