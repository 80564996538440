import * as changeCase from 'volcano/util/text'

import { useSelector, shallowEqual } from 'react-redux'
import { useMemo } from 'react'

import { getModelData, getEntityMetadata } from 'runic/systems/model/selectors'
import { getEntitySelector } from 'runic/core/selector'


export const parseField = ({name, modelData}) => {
  const fieldName = changeCase.camel(name)
  const fieldMetadata = {...modelData.fields[name], ...modelData.linkMap[name]}

  const label = fieldMetadata.label || fieldName
  const renderer = getRenderer(field)

  return { fieldMetadata, label, renderer }
}

export const getModelDataFromProps = (state, props) => {
  let modelName = props.modelName

  if (!modelName) {
    if (!props.modelIdentifier) throw Error('ERR: Model identifier or name required')
    const splitIdentifier = props.modelIdentifier.split('.')
    modelName = splitIdentifier[splitIdentifier.length -1]
  }

  const modelData = getModelData(state, modelName)
  const entityMetadata = getEntityMetadata(state, modelName)
  return {
    ...modelData,
    entityMetadata
  }
}

export const getListView = (state, props) => {
  if (props.editMode && state.ui.entityListEdit[props.modelName]) {
    return state.ui.entityListEdit[props.modelName]
  } else {
    if (props.ownerName) {
      const selector = props.selector || getEntitySelector(state, props.modelName).forOwnerSelector
      return selector(state, props.ownerName, props.ownerId, props.contextName)
    } else {
      const listViewName = props.listViewName || 'default'
      const selector = props.selector || getEntitySelector(state, props.modelName).createDynamicListSelector(listViewName)
      return selector(state)
    }
  }
}

export const getListViewV2 = (state, modelName, fetchKey) => state.orm.list[changeCase.camel(modelName)] ? state.orm.list[changeCase.camel(modelName)][fetchKey] || null : null


export const useModelDataFromProps = (modelName, modelIdentifier) => {
  if (!modelName) {
    if (!modelIdentifier) throw Error('ERR: Model identifier or name required')
    const splitIdentifier = modelIdentifier.split('.')
    modelName = splitIdentifier[splitIdentifier.length -1]
  }
  const modelData = useSelector(state => state.model.models[modelName])
  const actionMap = useSelector(state => modelData && state.action.actionMaps[modelData.identifier])
  // const entityMetadata = useSelector(state => state.orm.entityMetadata[changeCase.lowerCaseFirst(modelName)])

  return ({
    ...modelData,
    ...actionMap,
    // entityMetadata
  })

  // return useMemo(() => ({
  //   ...modelData,
  //   ...actionMap,
  //   entityMetadata
  // }), [modelData, actionMap, entityMetadata])
}