import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { Route, Switch, useMatch } from 'runic-aura/router'
import RunicSidebarZoneDefault from '../components/RunicSidebarZoneDefault'

import runicAuraActions from 'runic-aura/systems/runic-aura/actions'


const RunicZone = ({
  zone,
  baseUrl
}) => {
  const director = useDirector()
  const dispatch = useDispatch()
  const match = useMatch()
  const params = match.params

  React.useEffect(() => {
    dispatch(runicAuraActions.rncActivateZone({ zoneName: zone.name, zoneParams: params }))
  }, [])
  
  return (
    <Flex>
      <Box>
        <RunicSidebarZoneDefault baseUrl={baseUrl} />
      </Box>
      <Box mt={50} ml={30} width={1}>
        <Switch>
          {Object.values(zone.domains).map((domain, dx) => {
            const DomainHandler = director.directory.runicAura?.domainHandlers[domain.kind]
            const path = `${match.path}${domain.path}/`

            if (!DomainHandler) {
              console.log('Domain handler not found for domain', domain)
              return null
            }
            return (
              <Route key={dx} path={path}>
                <DomainHandler zone={zone} domain={domain} />
              </Route>
            )
          })}
        </Switch>
      </Box>
    </Flex>
  )
}

export default React.memo(RunicZone)
