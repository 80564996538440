import React from 'react'

// Vendor
import { useHistory } from "runic-aura/router"

// Riva
import useAppState from 'runic/hooks/useAppState'


const AuthenticationCheck = props => {
  const { children } = props
  const {isAuthenticated} = useAppState()
  const history = useHistory()

  React.useEffect(() => {
    if (!isAuthenticated) {
      if (process.browser) {
        // const rcTenantId = localStorage.getItem('rcTenantId')
        // if (rcTenantId !== null && rcTenantId !== undefined && director.rConfig.multiTenant) props.history.push(`/auth/login/${rcTenantId}`)
        // else props.history.push(`/auth/login`)
        history.push(`/auth/login`)
      } else {
        history.push(`/auth/login`)
      }
    }
  }, [])

  return isAuthenticated ? children : null

}

export default AuthenticationCheck
