import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import coreActions from 'runic/systems/core/actions'

// RunicAura
import { Switch, useMatch, Route } from 'runic-aura/router'
import RunicZone from 'runic-aura/apps/uitree/pages/RunicZone'
import RunicRouteManager from '../components/RunicRouteManager'


const RncTenantIndex = props => {
  const [isReady, setIsReady] = React.useState(false)
  const match = useMatch()
  const zones = useSelector(state => state.runicAura.zones)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(coreActions.rcrRivaTenantSetup({ rcTenantId: match.params.rcTenantId }, {
      success: () => setIsReady(true),
      // FIXME: add error callback, display error page
    }))
  }, [])

  if (!isReady) return null

  return (
    <>
      <RunicRouteManager />
      <Switch>
        {Object.values(zones).map((zone, dx) => (
          <Route key={dx} path={`${match.path}${zone.path}/`}>
            <RunicZone zone={zone} baseUrl={match.url} />
          </Route>
        ))}
      </Switch>
    </>
  )
}

export default RncTenantIndex
