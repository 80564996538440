import React from 'react'

// Rncui
import EntityFormField from 'runic-aura/apps/model/components/EntityFormField'
import ModelFormField from 'runic-aura/apps/model/components/ModelFormField'

// Reverb
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import Button from 'volcano/components/Button'
import Text from 'volcano/components/Text'
import { Box } from 'volcano'


const QuickForm = ({
  entity,
  buttonLabel,
  submitAction,
  fields,
  formData,
  onClose,
  onSuccess,
}) => {
  const [formProps, isSubmitting] = useRunicForm(submitAction, {
    action: (data) => ([{...data, ...formData}, {
      success: onSuccess,
    }])
  })

  return (
    <Form {...formProps}>
      {fields.map((field, dx) => (
        entity ? <EntityFormField key={dx} entity={entity} field={field} index={dx}/> : <ModelFormField key={dx} field={field} index={dx}/>
      ))}
      <Box mb={2}>
        <Button label={<Text textCase='title' id={buttonLabel}/>} fullWidth submit working={isSubmitting} />
      </Box>
      <Button label={<Text textCase='title' id={'cancel'}/>} fullWidth variant='border' onClick={onClose}/>
    </Form>
  )
}

export default QuickForm